/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import { useGetPaymentTypeListQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderWithBackArrowTwoSmallButtons from 'layouts/MainLayout/Headers/HeaderWithBackArrowTwoSmallButtons'
import HeaderTravelerAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderTravelerAction'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import PriceDiv from 'components/FieldWithInputAdornment/PriceDiv'

/* Type imports ------------------------------------------------------------- */
import type {
  ActeurTraveller,
  CodeValueDec,
  IndemnisationSinapps,
  ReglementTraveller,
} from 'API/__generated__/Api'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { TravelerForm } from 'types/TravelerForm'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 10px;
  align-items: center;
  justify-content: stretch;

  margin-bottom: 10px;
`

const FourGridContainer = styled(GridContainer)`
  grid-template-columns: repeat(4, 1fr);
  align-items: start;
`

const FourGridNoMarginContainer = styled(FourGridContainer)`
  margin-bottom: 0px;
`

interface TableRowProps {
  border?: boolean;
}

const TableRow = styled(FourGridNoMarginContainer)<TableRowProps>`
  padding-bottom: 15px;
  margin-top: 15px;
  align-items: center;
  border-bottom: ${(props) => props.border ? `1px solid ${props.theme.colors.grey}` : undefined};
`

const CardContentContainer = styled(CardContent)`
  padding-top: 0;
  padding-bottom: 0;
`

const FormSmallTitle = styled(FormBoldTitle)`
  font-size: 14px !important;
`

const FormContainer = styled.div`
  padding-bottom: 40px;
`

const CardContainer = styled(Card)`
  margin-bottom: 20px;
  font-size: 14px;
`

const BoldUppercaseTitle = styled(FormBoldTitle)`
  text-transform: uppercase;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CardTableContainer = styled(CardContainer)`
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: -10px;
  margin-bottom: -10px;
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (max-width: 768px) {
    overflow-x: overlay;
    margin-bottom: 0px;
  }
`

const BoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

/* Component declaration ---------------------------------------------------- */
interface AddPaymentPageProps {}

const AddPaymentPage: React.FC<AddPaymentPageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()

  const {
    currentData: paymentTypeList = [],
    isFetching: isFetchingPaymentTypeList,
  } = useGetPaymentTypeListQuery()

  useEffect(() => {
    formikForm.resetForm({
      values: {
        ...formikForm.values,
        newPayment: formikForm.initialValues.newPayment,
      },
    })
  }, [])

  const handleValue = (type: string, value?: string | boolean | number): void => {
    formikForm.setFieldValue(`newPayment.${type}`, value)
  }

  useEffect(() => {
    if (formikForm.values?.acteurs?.length > 0) {
      if (formikForm.values.newPayment.acteurId === '') {
        formikForm.setFieldValue('newPayment.acteurId', formikForm.values.acteurs[0].id)
      }
      formikForm.setFieldValue('newPayment.garantiesReglement', formikForm.values.acteurs[0].indemnisation?.indemnisationsSinapps)
    }
  }, [ formikForm.values?.acteurs ])

  const garantieList: IndemnisationSinapps[] = useMemo(() => formikForm.values?.acteurs?.[0]?.indemnisation?.indemnisationsSinapps || [], [ formikForm.values.acteurs ])

  useEffect(() => {
    if (formikForm.values?.acteurs?.length > 0) {
      const newGaranties: IndemnisationSinapps[] = garantieList
      formikForm.setFieldValue(
        'newPayment.garantiesReglement',
        newGaranties.map((garantie): CodeValueDec => ({ code: garantie.garantie?.code || '', libelle: garantie.garantie?.libelle || '', value: 0 })),
      )
    }
  }, [
    formikForm.values.newPayment.acteurId,
    formikForm.values?.acteurs,
  ])

  useEffect(() => {
    if (!isFetchingPaymentTypeList && paymentTypeList.length > 0) {
      formikForm.setFieldValue('newPayment.type', paymentTypeList[0])
    }
  }, [
    isFetchingPaymentTypeList,
    paymentTypeList,
  ])

  const addNewPayment = (goBack: boolean = false): void => {
    console.log('values', formikForm.values.newPayment)
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (errors.newPayment === undefined) {
          const acteurs: ActeurTraveller[] = structuredClone(formikForm.values.acteurs)
          const newPayment = formikForm.values.newPayment
          const createdPayment: ReglementTraveller = {
            ...newPayment,
            type: paymentTypeList.find((type) => type.type.code === newPayment.type?.code)?.type,
          }
          acteurs.find((acteur) => acteur.id === newPayment.acteurId)?.reglements?.push(createdPayment)

          formikForm.resetForm({
            values: {
              ...formikForm.values,
              acteurs,
              newPayment: formikForm.initialValues.newPayment,
            },
          })
          if (goBack) {
            navigate(`/dossiers/${caseId}/traveller/reglement`)
          }
        }
      })
      .catch(console.error)
  }

  const paymentNumber = useMemo(() => formikForm.values?.acteurs?.filter((person) => !person.isDeleted)?.flatMap((person) => person.reglements).length || 0, [ formikForm.values.acteurs ])

  if (formikForm.values.acteurs === undefined) {
    return null
  }

  const personOptions: SegmentedButtonOption<string>[] = formikForm.values?.acteurs?.filter((person) => !person.isDeleted).map((option) => ({ value: option.id, label: option.nom }))
  const paymentTypeOptions: SegmentedButtonOption<string>[] = paymentTypeList.map(({ type }) => ({ value: type.code, label: type.libelle }))
  const saisieOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Sur place (chèque expert)' }, { value: true, label: 'À effectuer' } ]

  return (
    <>
      <HeaderWithBackArrowTwoSmallButtons
        title="Ajout de règlement"
        caseId={caseId}
        onClickBack={() => navigate(`/dossiers/${caseId}/traveller/reglement`)}
      />
      <HeaderTravelerAction
        title={`${paymentNumber} règlement${paymentNumber > 1 ? 's' : ''} ajouté${paymentNumber > 1 ? 's' : ''}`}
      >
        <SubmitFormButton
          variant="outlined"
          disabled={formikForm.values.disabled}
          onClick={() => addNewPayment(true)}
        >
          Valider et terminer
        </SubmitFormButton>
        <SubmitFormButton
          variant="contained"
          disabled={formikForm.values.disabled}
          onClick={() => addNewPayment(false)}
        >
          Valider et ajouter nouveau
        </SubmitFormButton>
      </HeaderTravelerAction>
      <PageContainer>
        <FormContainer>
          <TravelerLargeTitle>
            {`Règlement ${paymentNumber + 1}`}
          </TravelerLargeTitle>
          <FormBoldTitle required>
            Destinataire
          </FormBoldTitle>
          <SegmentedButtons
            options={personOptions}
            selectedOption={formikForm.values.newPayment.acteurId}
            setSelectedOption={(newVal) => handleValue('acteurId', newVal)}
          />
          <FormBoldTitle>
            Commentaire
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Votre message"
            name="newPayment.commentaire"
            rows={3}
            multiline
          />
          <FormBoldTitle>
            Type
          </FormBoldTitle>
          <SegmentedButtons
            options={paymentTypeOptions}
            selectedOption={formikForm.values.newPayment.type?.code}
            setSelectedOption={(newVal) => handleValue('type.code', newVal)}
          />
          <br />
          <SegmentedButtons
            options={saisieOptions}
            selectedOption={formikForm.values.newPayment.surPlace || true}
            setSelectedOption={(newVal) => handleValue('surPlace', newVal)}
          />
          {
            formikForm.values.newPayment.surPlace &&
              <>
                <FormBoldTitle required>
                  Saisie
                </FormBoldTitle>
                <Card>
                  <CardContentContainer>
                    <FourGridNoMarginContainer>
                      <FormSmallTitle required>
                        Emetteur
                      </FormSmallTitle>
                      <FormSmallTitle required>
                        Date
                      </FormSmallTitle>
                      <FormSmallTitle required>
                        Numéro chèque
                      </FormSmallTitle>
                      <FormSmallTitle required>
                        Code tireur
                      </FormSmallTitle>
                    </FourGridNoMarginContainer>
                    <FourGridContainer>
                      <Field
                        component={TextField}
                        name="newPayment.emetteur"
                        placeholder="Emetteur"
                      />
                      <FormikDatePicker name="newPayment.dateReglement" />
                      <Field
                        component={TextField}
                        name="newPayment.numeroCheque"
                        placeholder="Numéro"
                      />
                      <Field
                        component={TextField}
                        name="newPayment.codeTireur"
                        placeholder="Code"
                      />
                    </FourGridContainer>
                  </CardContentContainer>
                </Card>
              </>
          }
          <FormBoldTitle>
            Indemnisation
          </FormBoldTitle>
          <Card>
            <CardTableContainer>
              <BoldUppercaseTitle>
                <TableRow>
                  <div>
                    Garantie
                  </div>
                  <div>
                    Indemnité immédiate
                  </div>
                  <div>
                    Indemnité différée
                  </div>
                  <div>
                    Montant
                  </div>
                </TableRow>
              </BoldUppercaseTitle>
              <BoldSeparator />
              {
                garantieList.map((value, index) => (
                  <TableRow
                    border={index !== (garantieList.length) -1}
                    key={`${value.garantie?.code}-${index}`}
                  >
                    <div>
                      {value.garantie?.libelle}
                    </div>
                    <PriceDiv price={value.montantIndemniteImmediateTTCApres} />
                    <PriceDiv price={value.montantIndemniteDiffereeTTCApres} />
                    <PriceField
                      name={`newPayment.garantiesReglement.[${index}].value`}
                      placeholder="Montant"
                      size="small"
                      value={formikForm.values.newPayment.garantiesReglement?.[index]?.value || 0}
                      onChange={(e) => handleValue(`garantiesReglement.[${index}].value`, e.target.value)}
                    />
                  </TableRow>
                ))
              }
              <BoldSeparator />
              <FormBoldTitle>
                <TableRow>
                  <div>
                    Totaux
                  </div>
                  <PriceDiv price={garantieList.reduce((a, b) => a + Number(b.montantIndemniteImmediateTTCApres), 0)} />
                  <PriceDiv price={garantieList.reduce((a, b) => a + Number(b.montantIndemniteDiffereeTTCApres), 0)} />
                </TableRow>
              </FormBoldTitle>
            </CardTableContainer>
          </Card>
        </FormContainer>
      </PageContainer>
    </>
  )
}

export default AddPaymentPage
