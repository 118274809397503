/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { isValidString } from 'helpers/isValidString'
import { hasCoordinates } from 'helpers/mapHelpers'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import {
  FmdGoodRounded,
  PhoneRounded,
} from '@mui/icons-material'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import OpenQuickActionsButton from 'components/IconButtons/OpenQuickActionsButton/OpenQuickActionsButton'
import OpenSidebarButton from 'components/IconButtons/OpenSidebarButton/OpenSidebarButton'
import GoToCasePageButton from 'components/IconButtons/GoToCasePageButton/GoToCasePageButton'
import DownloadOfflineCaseButton from 'components/IconButtons/DownloadOfflineCaseButton/DownloadOfflineCaseButton'

/* Type imports ------------------------------------------------------------- */
import type { Planning } from 'API/__generated__/Api'

/* Internal variables ------------------------------------------------------- */
interface EventCardProps {
  selected: boolean;
  color: string;
}

const EventCard = styled(Card)<EventCardProps>`
  height: 100%;
  width: 100%;
  margin: 10px 0px;
  border: ${(props) => props.selected ? `2px solid ${props.theme.palette.primary.main} !important` : 'inherit'};
`

const CardContentContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 15px 7fr 3fr;
  gap: 5px;
  align-items: center;
  justify-content: stretch;
  align-items: flex-start;
  padding: 10px;
`

interface ColoredBarProps {
  color: string;
}

const ColoredBar = styled.div<ColoredBarProps>`
  height: 100%;
  width: 7px;
  background-color: ${(props) => props.color || props.theme.palette.primary.main};
  border: ${(props) => props.color === '#FFFFFF' ? '1px solid #C0C0C0' : 'none'};
  border-radius: 3px;
`

const Name = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 15px;
`

const CommentLine = styled.div`
  font-size: .95rem;
`

const CaseContainer = styled.div`
  justify-self: end;
  text-align: end;
  margin-right: 5px;
  margin-bottom: 2px;
`

const CaseName = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 15px;
`

const CaseButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: -10px;
  margin-top: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningEventCardProps {
  event: Planning;
  selectedEvent?: string;
  setSelectedEvent?: (event: Planning, scrollToTop?: boolean) => void;
}

const PlanningEventCard: React.FC<PlanningEventCardProps> = ({
  event,
  selectedEvent,
  setSelectedEvent,
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const onLocationClick = () => {
    if (setSelectedEvent !== undefined) {
      setSelectedEvent(event, true)
    }
    if (location.pathname.indexOf('/carte') === -1) {
      navigate(`/planning/carte/${event.dateDebut}/${event.id}`)
    }
  }

  return (
    <EventCard
      id={event.id}
      selected={selectedEvent === event.id}
      onClick={() => setSelectedEvent && setSelectedEvent(event)}
      color={event.typeRDV?.color}
    >
      <CardContentContainer>
        <ColoredBar color={event.typeRDV?.color} />
        <div>
          <Name>
            {event.assure || event.typeRDV.libelle}
          </Name>
          <div>
            {event.natureSinistre?.libelle}
          </div>
          <div>
            {getAddress(event.adresse)}
          </div>
          <CommentLine>
            <div>
              {isValidString(event.adresse?.digicode) && `Digicode: ${event.adresse?.digicode}`}
            </div>
            <div>
              {event.commentaire}
            </div>
          </CommentLine>
        </div>
        {
          isValidString(event.refDossier?.refComplete) &&
            <CaseContainer>
              <CaseName>
                {'Dossier '}
                {event.refDossier?.refComplete}
              </CaseName>
              {`${event.compagnie?.libelle} - ${event.origine?.libelle}`}
              <CaseButtonsContainer>
                {
                  hasCoordinates(event.coordonnees) &&
                    <CustomIconButton
                      Icon={FmdGoodRounded}
                      onClick={onLocationClick}
                      variant="outlined"
                      color="secondary"
                      label="Afficher sur la carte"
                    />
                }
                {
                  isValidString(event.tel) &&
                    <a
                      href={`tel:${event.tel}`}
                      target="_self"
                    >
                      <CustomIconButton
                        Icon={PhoneRounded}
                        variant="outlined"
                        color="secondary"
                        label="Appeler"
                      />
                    </a>
                }
                {
                  event.refDossier?.refComplete && (
                    <>
                      <DownloadOfflineCaseButton
                        caseId={event.refDossier?.refComplete}
                        variant="outlined"
                        color="secondary"
                      />
                      <OpenSidebarButton
                        caseId={event.refDossier?.refComplete}
                        variant="outlined"
                        color="secondary"
                      />
                      <GoToCasePageButton
                        caseId={event.refDossier?.refComplete}
                        variant="outlined"
                        color="secondary"
                      />
                      <OpenQuickActionsButton
                        caseId={event.refDossier?.refComplete}
                        color="secondary"
                      />
                    </>
                  )
                }
              </CaseButtonsContainer>
            </CaseContainer>
        }
      </CardContentContainer>
    </EventCard>
  )
}

export default PlanningEventCard
