/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  Phone,
  SubdirectoryArrowRightRounded,
} from '@mui/icons-material'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'

/* Styled components -------------------------------------------------------- */
const BoldSeparator = styled.div`
  margin: 10px 0px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const TypeContainer = styled.div`
  font-size: .85rem;
`

const NameContainer = styled.div`
  font-weight: bold;
`

const SubIcon = styled(SubdirectoryArrowRightRounded)`
  font-size: 30px;
  color: ${(props) => props.theme.palette.secondary.main};
`

const CaseSidebarTiersItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const CaseSidebarTiersItemLeftSideContainer = styled.div`
  display: flex;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarTiersItemProps {
  child?: boolean;
  role?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  telephone?: string | null;
}

const CaseSidebarTiersItem: React.FC<CaseSidebarTiersItemProps> = ({
  child = false,
  role = '',
  firstName = '',
  lastName = '',
  telephone = '',
}) => {

  return (
    <>
      {
        child &&
          <BoldSeparator />
      }
      <CaseSidebarTiersItemContainer>
        <CaseSidebarTiersItemLeftSideContainer>
          {
            child &&
              <SubIcon />
          }
          <div>
            <TypeContainer>
              {role}
            </TypeContainer>
            <NameContainer>
              {firstName}
              {' '}
              {lastName}
            </NameContainer>
          </div>
        </CaseSidebarTiersItemLeftSideContainer>
        {
          isValidString(telephone) &&
            <a
              href={`tel:${telephone}`}
              target="_self"
            >
              <CustomIconButton
                Icon={Phone}
                variant="outlined"
                label="Appeler"
              />
            </a>
        }
      </CaseSidebarTiersItemContainer>
    </>
  )
}

export default CaseSidebarTiersItem
