/* Framework imports -------------------------------------------------------- */
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import * as Redux from 'react-redux'

/* Module imports ----------------------------------------------------------- */
import CssBaseline from '@mui/material/CssBaseline'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { frFR } from '@mui/x-date-pickers/locales'
import {
  L10n,
  setCulture,
  registerLicense,
  validateLicense,
} from '@syncfusion/ej2/base'
import fr from '@syncfusion/ej2-locale/src/fr.json'
import { fr as DateFr } from 'date-fns/locale'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import {
  persistor,
  store,
} from 'store/store'
import { openSWPopup } from 'store/slices/serviceWorkerSlice'
import * as ConnectivityService from 'services/ConnectivityService'
import { readConfig } from 'helpers/readConfig'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

/* Component imports -------------------------------------------------------- */
import App from './App/App'

/* Styling imports ---------------------------------------------------------- */
import '@syncfusion/ej2/material.css'
import 'react-toastify/dist/ReactToastify.css'
import 'leaflet/dist/leaflet.css'
import { emotionTheme } from './theme/emotionTheme'
import './theme/index.css'

/* React startup ------------------------------------------------------------ */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

/* Start service to check connectiviy */
ConnectivityService.start()

// Register syncfusion and add components translations
L10n.load({ 'fr':
{
  ...fr.fr,
  documenteditorcontainer: {
    ...fr.fr.documenteditorcontainer,
    'of': 'sur',
    'Comments': 'Commentaires',
    'Undo': 'Annuler',
    'Redo': 'Rétablir',
    'Table': 'Tableau',
    'Find': 'Rechercher',
    'Paste': 'Coller',
    'Copy': 'Copier',
  },
}})
setCulture('fr')
validateLicense(process.env.REACT_APP_SYNCFUSION_KEY)
registerLicense(process.env.REACT_APP_SYNCFUSION_KEY || '')

if (process.env.REACT_APP_LOGS === 'false') {
  console.log = () => {}
}

readConfig().then(() => {
  root.render(
    // <React.StrictMode>
    <Redux.Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={emotionTheme}>
            <LocalizationProvider
              adapterLocale={DateFr}
              dateAdapter={AdapterDateFns}
              localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
            >
              <CssBaseline />
              <App />
            </LocalizationProvider>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
      <ToastContainer
        position="top-center"
        draggable
        theme="colored"
      />
    </Redux.Provider>,
    // </React.StrictMode>,
  )
}).catch((error) => console.error('CONFIG LOAD ERROR : ', error))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(
  {
    onUpdate: (registration) => {
      const dispatch = store.dispatch
      dispatch(openSWPopup())
      if (registration && registration.waiting) {
        registration.waiting.postMessage(
          {
            type: 'SKIP_WAITING',
          },
        )
      }
    },
  },
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
