/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetApplicationGarantieListQuery,
  useGetCaseDocumentsQuery,
  useGetCaseInfosQuery,
  useGetCaseTravelerQuery,
  useGetCloseReportTypeListQuery,
  useGetReportTypeListQuery,
  useGetSinappsDocumentTypeListQuery,
  usePostCaseTravelerMutation,
} from 'store/api'
import { calculateAllCompensations } from 'services/CompensationService'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { enumToSegmentedButtonOptions } from 'helpers/enumToSegmentedButtonOptions'
import { isApiError } from 'helpers/fetchHelpers'
import { formatApiErrorMessage } from 'helpers/formatApiErrorMessage'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import Loader from 'components/Loader/Loader'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import DialogTitle from 'components/Dialog/DialogTitle'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type {
  ClotureTraveller,
  CodeLabel,
  DocumentAvecTypeRequest,
  GarantiePrincipale,
  TypeDocumentSinapps,
  TypeDocumentSinappsEnumLabel,
  TypeRapport,
  GestionUnique,
  CommandeDemanderFinPrestationRequest,
  CommandeDeposerConclusionsRequest,
} from 'API/__generated__/Api'
import {
  ApplicationGarantie,
  ClotureDossier,
  Verif,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
enum ReportType { 'REX', 'PRG' }
enum SinappsAction {
  'SendReport' = 'Envoi dépôt conclusion',
  'CloseSolication' = 'Demande fin de prestation',
}

interface ReportClosure extends ClotureTraveller {
  reportType: ReportType;
  isSinapps: boolean;
  sinappsAction: SinappsAction;
  conformiteDuRisque: Verif;
  descriptionDuRisque: string;
}

const reportClosureSchema = Yup.object().shape<Shape<ReportClosure>>({
  reportType: Yup.mixed<ReportType>().required(),
  isSinapps: Yup.boolean().required(),
  sinappsAction: Yup.mixed<SinappsAction>().required(),
  typeRapport: Yup.mixed<TypeRapport>().when('isSinapps', {
    is: false,
    then: () => Yup.mixed<TypeRapport>().required('Le type de rapport est obligatoire'),
  }),
  garantiePrincipale: Yup.object().shape<Shape<GarantiePrincipale>>({
    applicationGarantie: Yup.mixed<ApplicationGarantie>(),
    commentaireApplicationGarantie: Yup.string(),
    garantie: Yup.mixed<CodeLabel>(),
    caractereExpertise: Yup.mixed<CodeLabel>(),
  }).required(),
  gestionUnique: Yup.object().shape<Shape<GestionUnique>>({
    clotureDossier: Yup.mixed<ClotureDossier>().when('reportType', {
      is: ReportType.PRG,
      then: () => Yup.mixed<ClotureDossier>().required(),
    }),
    motifTransfert: Yup.string(),
  }).required(),
  depotConclusionSinapps: Yup.object().shape<Shape<CommandeDeposerConclusionsRequest>>({
    commentaire: Yup.string(),
    motif: Yup.string(),
    documents: Yup.array(Yup.mixed<DocumentAvecTypeRequest>().required()),
  }).required(),
  demandeFinPrestationSinapps: Yup.object().shape<Shape<CommandeDemanderFinPrestationRequest>>(({
    commentaire: Yup.string(),
    motif: Yup.string().when([ 'isSinapps', 'sinappsAction' ], {
      is: (isSinapps: boolean, sinappsAction: SinappsAction) => isSinapps && sinappsAction === SinappsAction.CloseSolication,
      then: () => Yup.string().required('Le motif est obligataoire'),
    }),
  })).required(),
  conformiteDuRisque: Yup.mixed<Verif>().required(),
  descriptionDuRisque: Yup.string().required('Ce champ est obligatoire'),
}).required()

type ReportClosureRequest = FormikContextType<ReportClosure>

/* Styled components -------------------------------------------------------- */
const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const FormButton = styled(Button)`
  min-width: 120px;
`

const FormContainer = styled.div`
  margin-bottom: 5px;
`

const TableCard = styled(Card)`
  font-size: 14px;
  padding: 0px 20px;
  margin: 10px 0px 20px;
`

const TableBoldUppercaseTitle = styled(FormBoldTitle)`
  text-transform: uppercase;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`

interface TableRowProps {
  border?: boolean;
}

const TableRow = styled.div<TableRowProps>`
  height: 100%;
  display: grid;
  gap: 10px;
  padding: 5px 0px;
  justify-content: stretch;
  align-items: center;
  border-bottom: ${(props) => props.border ? `1px solid ${props.theme.colors.grey}` : undefined};
  grid-template-columns: repeat(2, 1fr) 3fr 1fr auto;
  width: 100%;
`

const FileTableRow = styled(TableRow)`
  grid-template-columns: 40px repeat(5, 1fr);
`

const TableBoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

/* Component declaration ---------------------------------------------------- */
interface ReportClosureModalProps {
  handleClose: (isReportClosed?: boolean) => void;
  caseId: string;
  reportId: string;
}

const ReportClosureModal: React.FC<ReportClosureModalProps> = ({
  handleClose,
  caseId,
  reportId,
}) => {
  const {
    currentData: documentList = [],
    isFetching: isFetchingDocumentList,
  } = useGetCaseDocumentsQuery({ dossier: caseId }, { skip: !caseId })
  const {
    currentData: documentTypeList = [],
    isFetching: isFetchingDocumentTypeList,
  } = useGetSinappsDocumentTypeListQuery()
  const {
    currentData: reportTypeList = [],
    isFetching: isFetchingReportTypeList,
  } = useGetReportTypeListQuery(caseId, { skip: !caseId })
  const {
    currentData: garantieApplicationList = [],
    isFetching: isFetchingGarantieApplicationList,
  } = useGetApplicationGarantieListQuery()
  const {
    currentData: closeReportTypeList = [],
    isFetching: isFetchingCloseReportTypeList,
  } = useGetCloseReportTypeListQuery()
  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId, { skip: !caseId })
  const {
    currentData: travelerData,
    isFetching: isFetchingTravelerData,
  } = useGetCaseTravelerQuery(reportId, { skip: !reportId })
  const [ submitTraveller ] = usePostCaseTravelerMutation()

  const onSubmit = async (values: ReportClosure, { setSubmitting }: FormikHelpers<ReportClosure>) => {
    if (travelerData) {
      const data = structuredClone(travelerData)

      data.acteurs = data.acteurs.map((person) => {
        return ({
          ...person,
          indemnisation: calculateAllCompensations({
            damages: [
              ...person.pieces?.flatMap((room) => room.dommagesImmobilierEmbellissement) || [],
              ...person.dommagesMobilierDivers || [],
            ],
            isSinapps: caseInfos?.mission.origine?.code === 'SIN',
            oldCompensation: person.indemnisation,
          }),
        })
      })

      await submitTraveller({
        query: { id: reportId, validation: true },
        data: {
          ...data,
          ...values,
          conformiteDuRisque: {
            ...data.conformiteDuRisque,
            conformite: {
              ...data.conformiteDuRisque.conformite,
              commentaire: values.descriptionDuRisque,
              verification: values.conformiteDuRisque,
            },
          },
        },
      }).then((response) => {
        setSubmitting(false)
        if (isApiError(response)) {
          if (response.error.errors && 'sauvegarde' in response.error.errors) {
            toast.error(formatApiErrorMessage(response.error))
          } else {
            toast.error("Une erreur est survenue. Essayez d'enregistrer le rapport avec le bouton sur le traveller.")
          }
        } else {
          setSubmitting(false)
          handleClose(true)
        }
      }).catch(console.error)
    }
  }

  const isSinapps = useMemo(() => caseInfos?.mission.origine?.code === 'SIN', [ caseInfos?.mission.origine?.code ])

  const reportClosureForm: ReportClosureRequest = useForm<ReportClosure>(
    {
      initialValues: {
        reportType: ReportType.PRG,
        isSinapps,
        sinappsAction: SinappsAction.SendReport,
        typeRapport: '' as TypeRapport,
        garantiePrincipale: {
          applicationGarantie: ApplicationGarantie.SansReserve,
          commentaireApplicationGarantie: '',
          garantie: { code: '', libelle: '' },
          caractereExpertise: { code: '', libelle: '' },
        },
        gestionUnique: {
          clotureDossier: ClotureDossier.Clos,
          motifTransfert: '',
        },
        depotConclusionSinapps: {
          commentaire: '',
          motif: '',
          documents: [],
        },
        demandeFinPrestationSinapps: {
          commentaire: '',
          motif: '',
        },
        conformiteDuRisque: Verif.Oui,
        descriptionDuRisque: '',
      },
      onSubmit: onSubmit,
      validationSchema: reportClosureSchema,
    },
  )

  useEffect(() => {
    if (!isFetchingTravelerData && travelerData) {
      reportClosureForm.setFieldValue('conformiteDuRisque', travelerData.conformiteDuRisque.conformite?.verification)
      reportClosureForm.setFieldValue('descriptionDuRisque', travelerData.conformiteDuRisque.conformite?.commentaire)
    }
  }, [ isFetchingTravelerData ])

  const handleValue = (type: string, value?: string | boolean | string[] | DocumentAvecTypeRequest[]): void => {
    reportClosureForm.setFieldValue(type, value).catch(console.error)
  }

  const handleFileCheckAll = (): void => {
    if (reportClosureForm.values.depotConclusionSinapps?.documents?.length === documentList.length && reportClosureForm.values.depotConclusionSinapps.documents.length > 0) {
      handleValue('depotConclusionSinapps.documents', [])
    } else {
      handleValue('depotConclusionSinapps.documents', documentList.map((doc): DocumentAvecTypeRequest => ({ idFichier: doc.id || '', type: doc.categorieSinapps?.code })))
    }
  }

  const handleFileChecked = (fileId: string, fileType?: TypeDocumentSinappsEnumLabel): void => {
    if (reportClosureForm.values.depotConclusionSinapps?.documents?.some((doc) => doc.idFichier === fileId)) {
      handleValue('depotConclusionSinapps.documents', [ ...reportClosureForm.values.depotConclusionSinapps.documents.filter((doc) => doc.idFichier !== fileId) ])
    } else {
      handleValue('depotConclusionSinapps.documents', [ ...reportClosureForm.values.depotConclusionSinapps?.documents || [], { idFichier: fileId, type: fileType?.code } ])
    }
  }

  const modifyFileType = (fileId: string, fileType: string): void => {
    handleValue('depotConclusionSinapps.documents', [ ...(reportClosureForm.values.depotConclusionSinapps?.documents || []).filter((doc) => doc.idFichier !== fileId), { idFichier: fileId, type: fileType as TypeDocumentSinapps } ])
  }

  const isLoading = useMemo(() => isFetchingDocumentList || isFetchingDocumentTypeList|| isFetchingReportTypeList || isFetchingGarantieApplicationList || isFetchingCloseReportTypeList || isFetchingCaseInfos ||isFetchingTravelerData,
    [ isFetchingDocumentList, isFetchingDocumentTypeList, isFetchingReportTypeList, isFetchingGarantieApplicationList, isFetchingCloseReportTypeList, isFetchingCaseInfos, isFetchingTravelerData ])

  const garantieOptions: SegmentedButtonOption<string>[] = garantieApplicationList.map((value) => ({ value: value.code, label: value.libelle }))
  const clotureOptions: SegmentedButtonOption<string>[] = closeReportTypeList.map((value) => ({ value: value.code, label: value.libelle }))
  const sinappsOptions: SegmentedButtonOption<string>[] = enumToSegmentedButtonOptions(SinappsAction)
  const verifyOptions: SegmentedButtonOption<string>[] = [ { value: 'Oui' }, { value: 'Non' }, { value: 'NonVerifie', label: 'Non vérifié' } ]

  return (
    <Dialog
      open
      onClose={() => handleClose()}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        Clôture
        <CloseButton handleClose={() => handleClose()} />
      </DialogTitle>
      <DialogContentContainer>
        {
          isLoading ?
            <Loader /> :
            <FormContainer>
              <Form form={reportClosureForm}>
                {
                  !isSinapps && (
                    <>
                      <FormBoldTitle>
                        Type de rapport
                      </FormBoldTitle>
                      <Field
                        component={Select}
                        name="typeRapport"
                        displayEmpty
                        size="small"
                        value={reportClosureForm.values.typeRapport}
                        renderValue={verifySelectFieldValue(reportClosureForm.values.typeRapport)}
                      >
                        {
                          reportTypeList.map((value) => (
                            <MenuItem
                              value={value.code}
                              key={value.code}
                            >
                              {value.libelle}
                            </MenuItem>
                          ))
                        }
                      </Field>
                    </>
                  )
                }
                {
                  reportClosureForm.values.reportType === ReportType.PRG && (
                    <>
                      <FormBoldTitle>
                        Clôture du dossier
                      </FormBoldTitle>
                      <SegmentedButtons
                        smaller
                        options={clotureOptions}
                        selectedOption={reportClosureForm.values.gestionUnique.clotureDossier}
                        setSelectedOption={(newVal) => handleValue('gestionUnique.clotureDossier', newVal)}
                      />
                    </>
                  )
                }
                {
                  !isSinapps && (
                    <>
                      <FormBoldTitle>
                        Garanties
                      </FormBoldTitle>
                      <SegmentedButtons
                        smaller
                        options={garantieOptions}
                        selectedOption={reportClosureForm.values.garantiePrincipale?.applicationGarantie}
                        setSelectedOption={(newVal) => handleValue('garantiePrincipale.applicationGarantie', newVal)}
                      />
                      <FormBoldTitle>
                        Commentaire
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Votre message"
                        name="garantiePrincipale.commentaireApplicationGarantie"
                        rows={3}
                        multiline
                        size="small"
                      />
                    </>
                  )
                }
                <FormBoldTitle>
                  Conformité du risque
                </FormBoldTitle>
                <SegmentedButtons
                  smaller
                  options={verifyOptions}
                  selectedOption={reportClosureForm.values.conformiteDuRisque}
                  setSelectedOption={(newVal) => handleValue('conformiteDuRisque', newVal)}
                />
                <FormBoldTitle>
                  Descriptif du risque
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Votre message"
                  name="descriptionDuRisque"
                  rows={3}
                  multiline
                  size="small"
                />
                {
                  isSinapps && (
                    <>
                      <FormBoldTitle>
                        Sinapps
                      </FormBoldTitle>
                      <SegmentedButtons
                        smaller
                        options={sinappsOptions}
                        selectedOption={reportClosureForm.values.sinappsAction}
                        setSelectedOption={(newVal) => handleValue('sinappsAction', newVal)}
                      />
                      <FormBoldTitle>
                        Joindre des documents
                      </FormBoldTitle>
                      <TableCard>
                        <TableBoldUppercaseTitle>
                          <FileTableRow>
                            <Checkbox
                              onChange={handleFileCheckAll}
                              checked={reportClosureForm.values.depotConclusionSinapps?.documents?.length === documentList.length && documentList.length > 0}
                            />
                            <div>
                              Libellé
                            </div>
                            <div>
                              Date de création
                            </div>
                            <div>
                              Nom Document
                            </div>
                            <div>
                              Type
                            </div>
                            <div>
                              Origine
                            </div>
                          </FileTableRow>
                        </TableBoldUppercaseTitle>
                        <TableBoldSeparator />
                        {
                          documentList.map((document, index) => (
                            <FileTableRow
                              border={index !== (documentList.length || 0) -1}
                              key={`${document.id}-${index}`}
                            >
                              <Checkbox
                                checked={reportClosureForm.values.depotConclusionSinapps?.documents?.some((doc) => doc.idFichier === document.id)}
                                onChange={() => handleFileChecked(document.id || document.libelle, document.categorieSinapps)}
                              />
                              <div>
                                {document.libelle}
                              </div>
                              <div>
                                {DateUtils.APIStrToLocalDateString(document.dateCreation)}
                              </div>
                              <div>
                                {document.fileName}
                              </div>
                              {
                                document.categorieSinapps?.code &&
                                  <MuiSelect
                                    value={reportClosureForm.values.depotConclusionSinapps?.documents?.find((doc) => doc.idFichier === document.id)?.type || document.categorieSinapps?.code}
                                    onChange={(e): void => modifyFileType(document.id || document.libelle, e.target.value)}
                                    fullWidth
                                    displayEmpty
                                    size="small"
                                    disabled={isFetchingDocumentTypeList}
                                  >
                                    {
                                      documentTypeList.map(({ code, libelle }) => (
                                        <MenuItem
                                          value={code}
                                          key={code}
                                        >
                                          {libelle}
                                        </MenuItem>
                                      ))
                                    }
                                  </MuiSelect>
                              }
                              <div>
                                {document.origine}
                              </div>
                            </FileTableRow>
                          ))
                        }
                      </TableCard>
                    </>
                  )
                }
              </Form>
            </FormContainer>
        }
      </DialogContentContainer>
      <DialogActionContainer>
        <FormButton
          onClick={() => handleClose()}
        >
          Annuler
        </FormButton>
        <form onSubmit={reportClosureForm.handleSubmit}>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </form>
      </DialogActionContainer>
    </Dialog>
  )
}

export default ReportClosureModal
