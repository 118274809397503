/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  MenuItem,
  Select,
} from '@mui/material'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  align-items: end;
  justify-content: stretch;
  margin: 10px 0px;
`

const SelectLimitContainer = styled.div`
  justify-self: right;
`

/* Component declaration ---------------------------------------------------- */
interface PaginationButtonsProps {
  prevText: string;
  nextText: string;
  prevDisabled: boolean;
  nextDisabled: boolean;
  limit: number;
  onClickPrev: () => void;
  onClickNext: () => void;
  onChangeLimit: (value: number) => void;
  limitList?: number[];
}

const PaginationButtons: React.FC<PaginationButtonsProps> = ({
  prevText,
  nextText,
  prevDisabled,
  nextDisabled,
  onClickPrev,
  onClickNext,
  limit,
  onChangeLimit,
  limitList = [ 5, 10, 20, 50 ],
}) => {

  return (
    <GridContainer>
      <Button
        variant="outlined"
        disabled={prevDisabled}
        onClick={onClickPrev}
        startIcon={<ArrowBackIosNewRoundedIcon />}
      >
        {prevText}
      </Button>
      <Button
        variant="outlined"
        disabled={nextDisabled}
        onClick={onClickNext}
        endIcon={<ArrowForwardIosRoundedIcon />}
      >
        {nextText}
      </Button>
      <SelectLimitContainer>
        <Select
          value={limit}
          onChange={(e): void => onChangeLimit(parseInt(e.target.value.toString()))}
          size="small"
        >
          {
            limitList.map((limit, index) => (
              <MenuItem
                value={limit}
                key={`${limit}-${index}`}
              >
                {limit}
              </MenuItem>
            ))
          }
        </Select>
      </SelectLimitContainer>
    </GridContainer>
  )
}

export default PaginationButtons
