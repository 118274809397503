/* Framework imports -------------------------------------------------------- */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useOutletContext,
  useParams,
} from 'react-router-dom'
import {
  useGetDisasterSinappsNatureCauseDetailListQuery,
  useGetDisasterRexTypeListQuery,
  useGetCauseNotDeletedReasonListQuery,
  useGetCaseInfosQuery,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import {
  TextField,
  Select,
} from 'formik-mui'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'

/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { TravelerForm } from 'types/TravelerForm'
import type { CodeLabel } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const BodyTextField = styled(TextField)`
  margin-bottom: 40px;
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3 - 20px));
  gap: 30px;
  align-items: start;
`

/* Component declaration ---------------------------------------------------- */
interface DisasterPageProps {}

const DisasterPage: React.FC<DisasterPageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const { caseId = '' } = useParams<{ caseId: string }>()

  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId)
  const {
    currentData: natureCauseDetailList = [],
    isFetching: isFetchingNatureCauseDetailList,
  } = useGetDisasterSinappsNatureCauseDetailListQuery()
  const {
    currentData: disasterTypeList = [],
    isFetching: isFetchingDisasterTypeList,
  } = useGetDisasterRexTypeListQuery()
  const {
    currentData: causeNotDeletedReasonList = [],
    isFetching: isFetchingCauseNotDeletedReasonList,
  } = useGetCauseNotDeletedReasonListQuery()

  const handleValue = (type: string, value?: boolean | string | null | CodeLabel): void => {
    formikForm.setFieldValue(`sinistre.${type}`, value)
  }

  useEffect(() => {
    if (natureCauseDetailList.length <= 0 || !formikForm.values.sinistre?.infosSinapps) {
      return
    }
    if (natureCauseDetailList
      ?.find((nature) => nature.code === formikForm.values.sinistre.infosSinapps?.nature?.code)?.listeCauseSinapps
      ?.find((cause) => cause.code === formikForm.values.sinistre?.infosSinapps?.cause?.code)
    ) {
      if (natureCauseDetailList
        ?.find((nature) => nature.code === formikForm.values.sinistre.infosSinapps?.nature?.code)?.listeCauseSinapps
        ?.find((cause) => cause.code === formikForm.values.sinistre?.infosSinapps?.cause?.code)?.listeDetailSinapps
        ?.find((detail) => detail.code === formikForm.values.sinistre?.infosSinapps?.detail?.code)
      ) {
        return
      } else {
        handleValue('infosSinapps.detail', null)
      }
    } else {
      handleValue('infosSinapps.cause', null)
    }
  }, [ formikForm.values.sinistre?.infosSinapps?.nature, formikForm.values.sinistre?.infosSinapps?.cause ])

  const booleanOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]
  const disasterOptions: SegmentedButtonOption<string>[] = disasterTypeList.map(({ code, libelle }) => ({ value: code, label: libelle }))

  if (formikForm.values.sinistre === undefined) {
    return null
  }

  if ((isFetchingNatureCauseDetailList || isFetchingDisasterTypeList || isFetchingCauseNotDeletedReasonList || isFetchingCaseInfos))
    return <Loader />

  return (
    <div>
      <TravelerLargeTitle>
        Sinistre
      </TravelerLargeTitle>
      <FormBoldTitle>
        Type de rapport
      </FormBoldTitle>
      <SegmentedButtons
        options={disasterOptions}
        selectedOption={formikForm.values.sinistre.type?.code}
        setSelectedOption={(newVal) => handleValue('type.code', newVal)}
        disabled={formikForm.values.disabled}
      />
      {
        caseInfos?.mission.origine?.code === 'SIN' &&
          <React.Fragment>
            <FormBoldTitle bigger>
              Informations Sinapps
            </FormBoldTitle>
            <GridContainer>
              <FormBoldTitle>
                Nature Sinapps
              </FormBoldTitle>
              <FormBoldTitle>
                Cause Sinapps
              </FormBoldTitle>
              <FormBoldTitle>
                Détail Sinapps
              </FormBoldTitle>
            </GridContainer>
            <GridContainer>
              <Field
                component={Select}
                name="sinistre.infosSinapps.nature.code"
                displayEmpty
                value={formikForm.values.sinistre.infosSinapps?.nature?.code || ''}
                renderValue={verifySelectFieldValue(formikForm.values.sinistre.infosSinapps?.nature?.code)}
                disabled={formikForm.values.disabled}
              >
                {
                  natureCauseDetailList.map((nature) => (
                    <MenuItem
                      value={nature.code}
                      key={nature.code}
                    >
                      {nature.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
              <Field
                component={Select}
                name="sinistre.infosSinapps.cause.code"
                displayEmpty
                value={formikForm.values.sinistre.infosSinapps?.cause?.code || ''}
                renderValue={verifySelectFieldValue(formikForm.values.sinistre.infosSinapps?.cause?.code)}
                disabled={formikForm.values.disabled || (natureCauseDetailList.find((nature) => nature.code === formikForm.values.sinistre.infosSinapps?.nature?.code)?.listeCauseSinapps?.length || 0) <= 0}
              >
                {
                  natureCauseDetailList.find((nature) => nature.code === formikForm.values.sinistre.infosSinapps?.nature?.code)?.listeCauseSinapps?.map((cause) => (
                    <MenuItem
                      value={cause.code}
                      key={cause.code}
                    >
                      {cause.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
              <Field
                component={Select}
                name="sinistre.infosSinapps.detail.code"
                displayEmpty
                value={formikForm.values.sinistre.infosSinapps?.detail?.code || ''}
                renderValue={verifySelectFieldValue(formikForm.values.sinistre.infosSinapps?.detail?.code)}
                disabled={
                  formikForm.values.disabled ||
                  (natureCauseDetailList.find((nature) => nature.code === formikForm.values.sinistre.infosSinapps?.nature?.code)?.listeCauseSinapps
                    ?.find((cause) => cause.code === formikForm.values.sinistre.infosSinapps?.cause?.code)?.listeDetailSinapps?.length || 0) <= 0
                }
              >
                {
                  natureCauseDetailList.find((nature) => nature.code === formikForm.values.sinistre.infosSinapps?.nature?.code)?.listeCauseSinapps
                    ?.find((cause) => cause.code === formikForm.values.sinistre.infosSinapps?.cause?.code)?.listeDetailSinapps?.map((detail) => (
                      <MenuItem
                        value={detail.code}
                        key={detail.code}
                      >
                        {detail.libelle}
                      </MenuItem>
                    ))
                }
              </Field>
            </GridContainer>
            <FormBoldTitle>
              Concordance de la déclaration
            </FormBoldTitle>
            <SegmentedButtons
              options={booleanOptions}
              selectedOption={formikForm.values.sinistre.infosSinapps?.concordanceDeclaration}
              setSelectedOption={(newVal) => handleValue('infosSinapps.concordanceDeclaration', newVal)}
              disabled={formikForm.values.disabled}
            />
            {
              formikForm.values.sinistre.infosSinapps?.nature?.code === 'DegatsDesEaux' &&
                <>
                  <FormBoldTitle>
                    Cause supprimée
                  </FormBoldTitle>
                  <SegmentedButtons
                    options={booleanOptions}
                    selectedOption={formikForm.values.sinistre.infosSinapps?.causeSupprimee}
                    setSelectedOption={(newVal) => handleValue('infosSinapps.causeSupprimee', newVal)}
                    disabled={formikForm.values.disabled}
                  />
                  {
                    formikForm.values.sinistre.infosSinapps?.causeSupprimee === false &&
                      <>
                        <FormBoldTitle>
                          Motif cause non supprimée
                        </FormBoldTitle>
                        <Field
                          component={Select}
                          name="sinistre.infosSinapps.motifCauseNonSupprimee.code"
                          displayEmpty
                          value={formikForm.values.sinistre.infosSinapps?.motifCauseNonSupprimee?.code || ''}
                          renderValue={verifySelectFieldValue(formikForm.values.sinistre.infosSinapps?.motifCauseNonSupprimee?.code)}
                          disabled={formikForm.values.disabled}
                        >
                          {
                            causeNotDeletedReasonList.map((cause) => (
                              <MenuItem
                                value={cause.code}
                                key={cause.code}
                              >
                                {cause.libelle}
                              </MenuItem>
                            ))
                          }
                        </Field>
                        <br />
                      </>
                  }
                  <FormBoldTitle required={formikForm.values.sinistre.infosSinapps?.causeSupprimee === false && formikForm.values.sinistre.infosSinapps?.motifCauseNonSupprimee?.code === 'Autre'}>
                    Commentaire cause supprimée
                  </FormBoldTitle>
                  <Field
                    component={TextField}
                    placeholder="Votre message"
                    rows={3}
                    multiline
                    name="sinistre.infosSinapps.commentaireCauseSupprimee"
                    value={formikForm.values.sinistre.infosSinapps?.commentaireCauseSupprimee || ''}
                    disabled={formikForm.values.disabled}
                  />
                </>
            }
            <FormBoldTitle>
              Rappel des mesures conservatoires
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Votre message"
              rows={3}
              multiline
              name="sinistre.infosSinapps.commentaireMesuresConservatoires"
              value={formikForm.values.sinistre.infosSinapps?.commentaireMesuresConservatoires || ''}
              disabled={formikForm.values.disabled}
            />
          </React.Fragment>
      }
      <FormBoldTitle bigger>
        Causes, dommages et circonstances
      </FormBoldTitle>
      <FormBoldTitle>
        Circonstances
      </FormBoldTitle>
      <Field
        component={TextField}
        placeholder="Votre message"
        rows={3}
        multiline
        name="sinistre.commentaireCirconstance"
        value={formikForm.values.sinistre.commentaireCirconstance || ''}
        disabled={formikForm.values.disabled}
      />
      <FormBoldTitle>
        Causes
      </FormBoldTitle>
      <Field
        component={TextField}
        placeholder="Votre message"
        rows={3}
        multiline
        name="sinistre.commentaireCause"
        value={formikForm.values.sinistre.commentaireCause || ''}
        disabled={formikForm.values.disabled}
      />
      <FormBoldTitle>
        Dommages
      </FormBoldTitle>
      <Field
        component={TextField}
        placeholder="Votre message"
        rows={3}
        multiline
        name="sinistre.commentaireDommages"
        value={formikForm.values.sinistre.commentaireDommages || ''}
        disabled={formikForm.values.disabled}
      />
      <FormBoldTitle>
        Montant dommages estimés
      </FormBoldTitle>
      <PriceField
        placeholder="Montant dommages estimés"
        name="sinistre.montantDommageAssure"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => formikForm.setFieldValue('sinistre.montantDommageAssure', e.target.value)}
      />
      <FormBoldTitle>
        Autres infos
      </FormBoldTitle>
      <Field
        component={BodyTextField}
        placeholder="Votre message"
        rows={3}
        multiline
        name="sinistre.commentaireAutresInfos"
        value={formikForm.values.sinistre.commentaireAutresInfos || ''}
        disabled={formikForm.values.disabled}
      />
    </div>
  )
}

export default DisasterPage
