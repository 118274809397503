/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import { Outlet } from 'react-router-dom'

/* Module imports ----------------------------------------------------------- */
import {
  useGetNotificationCountQuery,
  useGetTaskCountQuery,
  useLazyGetInternalExchangesCountQuery,
} from 'store/api'
import { useAuthInfo } from 'store/hooks'
import { useMobileStatusBarStyle } from 'helpers/hooks/useMobileStatusBarStyle'
import { useCaseSidebar } from 'helpers/hooks/useCaseSidebar'

/* Component imports -------------------------------------------------------- */
import { Drawer } from '@mui/material'
import { toast } from 'react-toastify'
import ErrorBoundaryPage from 'layouts/MainLayout/ErrorBoundaryPage/ErrorBoundaryPage'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import QuickActionsModal from 'layouts/MainLayout/QuickActions/QuickActionsModal'
import CaseSidebar from 'layouts/CaseSidebar/CaseSidebar'
import MediaLibraryBottomSheet from 'layouts/MainLayout/MediaLibraryBottomSheet/MediaLibraryBottomSheet'
import NavBar from './NavBar/NavBar'
import NetworkStatusBanner from './NetworkStatusBanner/NetworkStatusBanner'

/* Component declaration ---------------------------------------------------- */
interface MainLayoutProps {}

const MainLayout: React.FC<MainLayoutProps> = () => {
  const authInfo = useAuthInfo()
  const caseSidebar = useCaseSidebar()
  const [ prevInternalExchangeCount, setPrevInternalExchangeCount ] = useState<number>(0)

  useMobileStatusBarStyle('black')

  const { currentData: notifications = 0 } = useGetNotificationCountQuery({ destinataire: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '', limit: 100 })
  const { currentData: tasks = 0 } = useGetTaskCountQuery({ collaborateur: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '', limit: 100 })
  const [ getInternalExchanges, { currentData: internalExchanges = 0 } ] = useLazyGetInternalExchangesCountQuery()

  const fetchInternalExchanges = () => {
    getInternalExchanges({
      destinataire:
      authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '',
      limit: 100,
    })
  }

  useEffect(() => {
    if ('Notification' in window && Notification.permission === 'default') {
      Notification.requestPermission()
    }
    fetchInternalExchanges()

    const interval = setInterval(fetchInternalExchanges, 120000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (internalExchanges > prevInternalExchangeCount && prevInternalExchangeCount !== 0) {
      const notificationOptions = {
        body: 'Vous avez un nouvel échange interne.',
        icon: '/cropped-Favicon-192x192.png',
        badge: '/cropped-Favicon-192x192.png',
        title: 'Nouvel Échange Interne !',
      }
      toast.info(notificationOptions.body)

      if ('Notification' in window && Notification.permission === 'granted') {
        new Notification(notificationOptions.title, notificationOptions)
      }

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then((reg) => {
          reg?.showNotification(notificationOptions.title, notificationOptions)
        }).catch(console.error)
      }
    }
    setPrevInternalExchangeCount(internalExchanges)
  }, [ internalExchanges ])

  return (
    <BaseLayout>
      <NetworkStatusBanner />
      <Drawer
        open={caseSidebar.state.open}
        onClose={() => caseSidebar.close()}
        id="case-sidebar"
        anchor="right"
      >
        {
          caseSidebar.state.caseId &&
            <CaseSidebar
              caseId={caseSidebar.state.caseId}
              handleClose={() => caseSidebar.close()}
            />
        }
      </Drawer>
      <ErrorBoundaryPage>
        <Outlet />
        <QuickActionsModal />
        <MediaLibraryBottomSheet />
      </ErrorBoundaryPage>
      <NavBar
        exchangesCount={internalExchanges}
        tasksCount={tasks}
        notificationsCount={notifications}
      />
    </BaseLayout>
  )
}

export default MainLayout
