/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component declaration ---------------------------------------------------- */
const PageContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  background-color: ${(props) => props.theme.colors.lightgrey};
  padding: 0 max(calc(30vw - 300px), 1rem) !important;

  @media screen and (max-width: 768px) {
    padding: 0 0.5rem !important;
  }
`

export default PageContainer
