/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import ActionTitle from 'components/ActionTitle/ActionTitle'
import HeaderContainer from './HeaderContainer'

/* Styled components -------------------------------------------------------- */
const HeaderForm = styled.form`
  margin-left: auto;
  margin-right: 15px;
`

/* Component declaration ---------------------------------------------------- */
interface HeaderActionProps {
  title: React.ReactNode;
  children: React.ReactNode;
  onSubmit: () => void;
}

const HeaderAction: React.FC<HeaderActionProps> = ({ title, children, onSubmit }) => {
  return (
    <HeaderContainer justifyContent="start">
      <ActionTitle>
        {title}
      </ActionTitle>
      <HeaderForm onSubmit={onSubmit}>
        {children}
      </HeaderForm>
    </HeaderContainer>
  )
}

export default HeaderAction
