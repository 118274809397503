/* eslint-disable @typescript-eslint/no-unused-vars */
/* Framework imports -------------------------------------------------------- */
import * as RTKQuery from '@reduxjs/toolkit/query/react'

/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import { processRequest } from 'helpers/fetchHelpers'
import { apiUrl } from 'helpers/readConfig'

/* Type imports ------------------------------------------------------------- */
import type {
  LoginResponse,
  LoginRequest,
  PlanningDetailParams,
  CodeLabel,
  DocumentDossierDetailParams,
  EvenementielDocumentDetailParams,
  DossierRecherche,
  PieceJointe,
  Planning,
  TypeRdv,
  Difficulte,
  MailDestinataire,
  MailType,
  SuiviSimplifie,
  EchangeInterne,
  NatureSinapps,
  QuestionREX,
  TypeReglement,
  Irsi,
  Adresse,
  FamilleNatureSinistre,
  NatureSinistre,
  CodeEtatDossier,
  MotifDifficulte,
  CommandeSolliciterIntervenantRequest,
  Sollicitation,
  CloturerSollicitationSinappsCreatePayload,
  FamilleAnnuaire,
  DestinatairesMailDetailParams,
  EchangeInterneRequest,
  CommandeDemanderFinPrestationRequest,
  CommandeLeverFinPrestationRequest,
  CommandeSignalerUneDifficulteRequest,
  LeverDifficulteSinappsCreatePayload,
  CommandeTraiterInsatisfactionRequest,
  CommandeCloturerInsatisfactionRequest,
  DestinatairesEchangeInterneDetailParams,
  MailRequest,
  ListesDossierDetailParams,
  CommentaireDossierRequest,
  CommentaireDossier,
  TauxTVA,
  RechercheDossierListParams,
  EchangeInterneListParams,
  CategoriePJ,
  CategoriesPjListParams,
  Traveller,
  RapportResume,
  TravellerDossierCreateParams,
  ProblemDetails,
  Tache,
  TachesListParams,
  ReporterTachesCreateParams,
  PlanningActionGeneraleRequest,
  SolderTachesCreateParams,
  NotificationsListParams,
  Pastilles,
  PastillesDossiersListParams,
  RepondreEchangeInterneRequest,
  TransfertEchangeInterneRequest,
  CommandeDeposerCompteRenduRequest,
  ResteAFaire,
  JalonSimplifie,
  Dossier,
  IrsiRequest,
  IrsiTravellerCreateParams,
  RechercheAnnuaireListParams,
  FamillesAnnuaireListParams,
  AnnuaireResume,
  IntervenantTraveller,
  InitIntervenantTravellerDetailParams,
  FamilleAction,
  FamilleEtActionsEvenementielDetailParams,
  DataDocument,
  Courrier,
  EvenementielDestinataire,
  DocumentDeleteParams,
  NatureBien,
  CountNotificationsListParams,
  CountTachesListParams,
  ValidationProblemDetails,
  DocumentDossierCreatePayload,
  TacheRapideRequest,
  TachesCreateParams,
  FicheVigilanceRequest,
  FicheVigilance,
  TrancheIRSI,
  TypeSollicitationAutorisee,
  TypeSollicitation,
  MotifRectification,
  BlocNoteDossierCreatePayload,
  BlocNote,
  UpdateFichierDocumentCreatePayload,
  UpdateDocumentRequest,
  NbNonLuEchangeInterneListParams,
} from 'API/__generated__/Api'
import type { Ditseuqered } from 'types/Ditseuqered'

/* API Redux service -------------------------------------------------------- */
export const api = RTKQuery.createApi(
  {
    reducerPath: 'api',
    baseQuery: RTKQuery.fetchBaseQuery({ baseUrl: apiUrl }) as RTKQuery.BaseQueryFn<string | RTKQuery.FetchArgs, unknown, ValidationProblemDetails>,
    // Data is not refresh during 12h when not needed
    keepUnusedDataFor: 43200,
    tagTypes: [
      'AUTH',
      'CASE',
      'PLANNINGS',
      'EXCHANGES',
      'TASKS',
      'SINAPPS_DIFFICULTIES',
      'SINAPPS_SOLICITATIONS',
      'SINAPPS_VIGILANCE_REPORTS',
      'MEDIAS',
      'IRSI',
      'EVENT_DOCUMENTS',
      'NOTEPAD',
    ],
    endpoints: (build) => {
      return {
        /* Authentification */
        authenticate: build.mutation<LoginResponse, Ditseuqered<LoginRequest>>({
          queryFn: async (args) => processRequest(
            OpenAPI.authentification.loginAuthentificationCreate,
            args,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'AUTH' ],
        }),
        /* Case */
        getCasesByStatus: build.query<DossierRecherche[], ListesDossierDetailParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.listesDossierDetail,
            args,
          ),
        }),
        getCasesByFilters: build.query<DossierRecherche[], RechercheDossierListParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.rechercheDossierList,
            args,
          ),
        }),
        getCasesChipList: build.query<Pastilles[], PastillesDossiersListParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.pastillesDossiers.pastillesDossiersList,
            args,
          ),
        }),
        getCaseReports: build.query<RapportResume[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.rapportsDossierDetail,
            args,
          ),
        }),
        getCaseTraveler: build.query<Traveller, string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.travellerDossierDetail,
            args,
          ),
        }),
        postCaseTraveler: build.mutation<ProblemDetails, Ditseuqered<{ query: TravellerDossierCreateParams; data: Traveller }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.travellerDossierCreate,
            args.query,
            args.data,
          ),
        }),
        postNewReport: build.mutation<Traveller, Ditseuqered<string>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.nouveauTravellerCreate,
            args,
          ),
        }),
        /* Sidebar */
        getCaseWorkflow: build.query<JalonSimplifie[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.workflowDossierDetail,
            args,
          ),
        }),
        getCaseInfos: build.query<Dossier, string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.dossierDetail,
            args,
          ),
        }),
        getHistorique: build.query<SuiviSimplifie[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.historiqueDossierDetail,
            args,
          ),
        }),
        getSinappsPortal: build.query<string, string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.portailSinappsDossierDetail,
            args,
          ),
        }),
        getIrsiPortal: build.query<string, string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.portailIrsiDossierDetail,
            args,
          ),
        }),
        getEpassPortal: build.query<string, string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.portailEpassDossierDetail,
            args,
          ),
        }),
        /* Notifications */
        getNotifications: build.query<EchangeInterne[], NotificationsListParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.notifications.notificationsList,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'EXCHANGES', id: 'LIST' },
            ...result.map((r) => ({ type: 'EXCHANGES' as const, id: `${arg.dossier}-${r.id}` })),
          ],
        }),
        getNotificationCount: build.query<number, CountNotificationsListParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.notifications.countNotificationsList,
            args,
          ),
        }),
        /* Planning */
        getPlanning: build.query<Planning[], PlanningDetailParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.planning.planningDetail,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'PLANNINGS', id: 'LIST' },
            ...result.map((r) => ({ type: 'PLANNINGS' as const, id: `${arg.expert}-${r.id}` })),
          ],
        }),
        getPlanningTypes: build.query<TypeRdv[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.planning.typesPlanningList,
          ),
        }),
        postAddGeneralAction: build.mutation<void, Ditseuqered<{ data: PlanningActionGeneraleRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.planning.nouvelleActionGeneralePlanningCreate,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'PLANNINGS' ],
        }),
        postEditGeneralAction: build.mutation<string, Ditseuqered<{id: string; data: PlanningActionGeneraleRequest}>>({
          queryFn: async (args) => processRequest(
            OpenAPI.planning.modifieActionGeneralePlanningCreate,
            args.id,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'PLANNINGS' ],
        }),
        /* Quick Actions - SINAPPS */
        postSinappsRequestServiceEnd: build.mutation<boolean, Ditseuqered<{ caseId: string; data: CommandeDemanderFinPrestationRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.demanderFinPrestationSinappsCreate,
            args.caseId,
            args.data,
          ),
        }),
        getSinappsRequestServiceEndReasonList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.motifsFinPrestationSinappsList,
          ),
        }),
        postSinappsLiftServiceEnd: build.mutation<boolean, Ditseuqered<{ caseId: string; data: CommandeLeverFinPrestationRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.leverFinPrestationSinappsCreate,
            args.caseId,
            args.data,
          ),
        }),
        postSinappsSignalDifficulty: build.mutation<boolean, Ditseuqered<{ caseId: string; data: CommandeSignalerUneDifficulteRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.signalerUneDifficulteSinappsCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'SINAPPS_DIFFICULTIES' ],
        }),
        getSinappsDifficultyStepList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.etapesSinappsList,
          ),
        }),
        getSinappsDifficultyReasonList: build.query<MotifDifficulte[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.motifsDifficultesSinappsList,
          ),
        }),
        postSinappsLiftDifficulty: build.mutation<boolean, Ditseuqered<{ caseId: string; data: LeverDifficulteSinappsCreatePayload }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.leverDifficulteSinappsCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'SINAPPS_DIFFICULTIES' ],
        }),
        getSinappsDifficultyList: build.query<Difficulte[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.difficultesSinappsDetail,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'SINAPPS_DIFFICULTIES', id: 'LIST' },
            ...result.map((r) => ({ type: 'SINAPPS_DIFFICULTIES' as const, id: `${arg}-${r.refDossierCpt.cpt}` })),
          ],
        }),
        postSinappsDealDissatisfaction: build.mutation<boolean, Ditseuqered<{ caseId: string; data: CommandeTraiterInsatisfactionRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.traiterInsatisfactionSinappsCreate,
            args.caseId,
            args.data,
          ),
        }),
        getSinappsDocumentTypeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.typesDocumentsSinappsList,
          ),
        }),
        postSinappsCloseDissatisfaction: build.mutation<boolean, Ditseuqered<{ caseId: string; data: CommandeCloturerInsatisfactionRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.cloturerInsatisfactionSinappsCreate,
            args.caseId,
            args.data,
          ),
        }),
        getSinappsCloseDissatisfactionReasonList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.motifClotureInsatisfactionList,
          ),
        }),
        /* Quick Actions - SINAPPS - Solicit Stakeholder  */
        postSinappsSolicitStakeholder: build.mutation<boolean, Ditseuqered<{ caseId: string; data: CommandeSolliciterIntervenantRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.solliciterIntervenantSinappsCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'SINAPPS_SOLICITATIONS' ],
        }),
        getSinappsSolicitationList: build.query<Sollicitation[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.sollicitationsSinappsDetail,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'SINAPPS_SOLICITATIONS', id: 'LIST' },
            ...result.map((r) => ({ type: 'SINAPPS_SOLICITATIONS' as const, id: `${arg}-${r.guid}` })),
          ],
        }),
        postSinappsCloseSolicitation: build.mutation<boolean, Ditseuqered<{ caseId: string; data: CloturerSollicitationSinappsCreatePayload }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.cloturerSollicitationSinappsCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'SINAPPS_SOLICITATIONS' ],
        }),
        getSinappsSolicitInterventionTypeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.typeInterventionList,
          ),
        }),
        getSinappsSolicitPrestationList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.canauxPrestationSinappsList,
          ),
        }),
        getSinappsSolicitDamageNatureList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.naturesDommageSinappsList,
          ),
        }),
        getSinappsSolicitInterventionSubtypeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.sousTypeInterventionList,
          ),
        }),
        getSinappsSolicitOperationNatureList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.natureOperationList,
          ),
        }),
        getSinappsSolicitMandateEntityList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.entiteAMandaterList,
          ),
        }),
        getSinappsSolicitCriticiteList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.criticitesList,
          ),
        }),
        getSinappsSolicitUrgencyReasonsList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.motifsUrgenceSinappsList,
          ),
        }),
        getSinappsSolicitConventionList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.conventionsSinappsList,
          ),
        }),
        getSinappsSolicitIrsiTrancheList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.tranchesIrsiSinappsList,
          ),
        }),
        getSinappsSolicitTypeList: build.query<TypeSollicitationAutorisee[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.typeSollicitationAutoriseesDetail,
            args,
          ),
        }),
        getSinappsSolicitStepList: build.query<CodeLabel[], TypeSollicitation>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.etapesSollicitationDetail,
            args,
          ),
        }),
        getSinappsSolicitCorrectionReasonList: build.query<MotifRectification[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.motifsRectificationSinappsList,
          ),
        }),
        /* Quick Actions - SINAPPS - Vigilance Report  */
        postSinappsVigilanceReport: build.mutation<boolean, Ditseuqered<{ caseId: string; data: FicheVigilanceRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.creerUneFicheSinappsCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'SINAPPS_VIGILANCE_REPORTS' ],
        }),
        getSinappsVigilanceReportList: build.query<FicheVigilance[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.fichesVigilanceSinappsDetail,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'SINAPPS_VIGILANCE_REPORTS', id: 'LIST' },
            ...result.map((r) => ({ type: 'SINAPPS_VIGILANCE_REPORTS' as const, id: `${arg}-${r.guidVigilance}` })),
          ],
        }),
        deleteSinappsVigilanceReport: build.mutation<boolean, Ditseuqered<{ caseId: string; id: string }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.supprimerUneFicheSinappsCreate,
            args.caseId,
            args.id,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'SINAPPS_VIGILANCE_REPORTS' ],
        }),
        getSinappsVigilanceReportAlertQualificationList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.qualificationAlerteList,
          ),
        }),
        getSinappsVigilanceReportDisasterContextList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.circonstanceSinistreList,
          ),
        }),
        getSinappsVigilanceReportStakeholderBehaviourList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.comportementIntervenantList,
          ),
        }),
        getSinappsVigilanceReportCaseContextList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.contexteDossierList,
          ),
        }),
        getSinappsVigilanceReportContractElementList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.elementContratList,
          ),
        }),
        getSinappsVigilanceReportExagerationList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.exagerationList,
          ),
        }),
        getSinappsVigilanceReportSupportingDocumentList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.pieceJustificativeList,
          ),
        }),
        /* Quick Actions - Comment */
        getCommentAction: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.actionsCommentaireList,
          ),
        }),
        postNewComment: build.mutation<CommentaireDossier[], Ditseuqered<{ caseId: string; data: CommentaireDossierRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.nouveauCommentaireDossierCreate,
            args.caseId,
            args.data,
          ),
        }),
        /* Quick Actions - Mail */
        getMailSenders: build.query<MailDestinataire[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.emetteursMailDetail,
            args,
          ),
        }),
        getMailRecipients: build.query<MailDestinataire[], DestinatairesMailDetailParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.destinatairesMailDetail,
            args,
          ),
        }),
        getMailTypeList: build.query<CodeLabel[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.mailTypesMailDetail,
            args,
          ),
        }),
        getMailType: build.query<MailType, {caseId: string; code: string}>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.mailTypeDossierDetail,
            args.caseId,
            args.code,
          ),
        }),
        postNewMail: build.mutation<void, Ditseuqered<{ caseId: string; data: MailRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.nouveauMailCreate,
            args.caseId,
            args.data,
          ),
        }),
        /* Quick Actions - Internal Exchanges */
        getInternalExchanges: build.query<EchangeInterne[], EchangeInterneListParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.echangeInterne.echangeInterneList,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'EXCHANGES', id: 'LIST' },
            ...result.map((r) => ({ type: 'EXCHANGES' as const, id: `${r.id}` })),
          ],
        }),
        getInternalExchangesCount: build.query<number, NbNonLuEchangeInterneListParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.echangeInterne.nbNonLuEchangeInterneList,
            args,
          ),
        }),
        getInternalExchangesRecipientList: build.query<CodeLabel[], DestinatairesEchangeInterneDetailParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.destinatairesEchangeInterneDetail,
            args,
          ),
        }),
        getInternalExchangesMessageTypeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.echangeInterne.echangesTypeEchangeInterneList,
          ),
        }),
        postNewInternalExchange: build.mutation<void, Ditseuqered<{ caseId: string; data: EchangeInterneRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.nouveauEchangeInterneCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'EXCHANGES' ],
        }),
        postViewedInternalExchange: build.mutation<void, Ditseuqered<{id: string}>>({
          queryFn: async (args) => processRequest(
            OpenAPI.echangeInterne.marquerCommeLuEchangeInterneCreate,
            args.id,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'EXCHANGES' ],
        }),
        postNotViewedInternalExchange: build.mutation<void, Ditseuqered<{ id: string }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.echangeInterne.marquerCommeNonLuEchangeInterneCreate,
            args.id,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'EXCHANGES' ],
        }),
        postAnswerInternalExchange: build.mutation<void, Ditseuqered<{ id: string; data: RepondreEchangeInterneRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.echangeInterne.repondreEchangeInterneCreate,
            args.id,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'EXCHANGES' ],
        }),
        postForwardInternalExchange: build.mutation<void, Ditseuqered<{ id: string; data: TransfertEchangeInterneRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.echangeInterne.transfertEchangeInterneCreate,
            args.id,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'EXCHANGES' ],
        }),
        /* Quick Actions - Task */
        getTasks: build.query<Tache[], TachesListParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.taches.tachesList,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'TASKS', id: 'LIST' },
            ...result.map((r) => ({ type: 'TASKS' as const, id: `${r.id}` })),
          ],
        }),
        getTaskCount: build.query<number, CountTachesListParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.taches.countTachesList,
            args,
          ),
        }),
        postTask: build.mutation<Tache[], Ditseuqered<{query: TachesCreateParams; data: TacheRapideRequest}>>({
          queryFn: async (args) => processRequest(
            OpenAPI.taches.tachesCreate,
            args.query,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'TASKS' ],
        }),
        postCompleteTask: build.mutation<boolean, Ditseuqered<SolderTachesCreateParams>>({
          queryFn: async (args) => processRequest(
            OpenAPI.taches.solderTachesCreate,
            args,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'TASKS' ],
        }),
        postPostponeTask: build.mutation<void, Ditseuqered<ReporterTachesCreateParams>>({
          queryFn: async (args) => processRequest(
            OpenAPI.taches.reporterTachesCreate,
            args,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'TASKS' ],
        }),
        /* Traveler - Risk */
        getRiskUsageList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.usagesRisqueList,
          ),
        }),
        getRiskTypeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.typesRisqueList,
          ),
        }),
        getRiskNotVerifiedReasonList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.motifsNonVerificationRisqueSinappsList,
          ),
        }),
        getDetailBienRexList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.detailDeBienRexList,
          ),
        }),
        /* Traveler - Stakeholders */
        getStakeholderDirectoryList: build.query<AnnuaireResume[], RechercheAnnuaireListParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.annuaire.rechercheAnnuaireList,
            args,
          ),
        }),
        getStakeholderFamilleAnnuaireList: build.query<CodeLabel[], FamillesAnnuaireListParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.annuaire.famillesAnnuaireList,
            args,
          ),
        }),
        getTravelerStakeholderInfos: build.query<IntervenantTraveller, InitIntervenantTravellerDetailParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.initIntervenantTravellerDetail,
            args,
          ),
        }),
        /* Traveler - Disaster */
        getDisasterSinappsNatureCauseDetailList: build.query<NatureSinapps[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.naturesCausesDetailsSinappsList,
          ),
        }),
        getDisasterRexTypeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.typeRexSinistreList,
          ),
        }),
        getCauseNotDeletedReasonList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.motifsNonSuppressionCauseSinappsList,
          ),
        }),
        /* Traveler - Damages */
        getRoomTypeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.typesPieceList,
          ),
        }),
        getNatureDetailBienList: build.query<NatureBien[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.natureEtDetailDeBienDossierDetail,
            args,
          ),
        }),
        getNatureBienList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.naturesBienRexList,
          ),
        }),
        getReparabiliteList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.reparabilitesList,
          ),
        }),
        getJustificationList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.justificationsList,
          ),
        }),
        /* Traveler - Compensation */
        getCompensationDamagePositionList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.positionsDommagesIndemnisationList,
          ),
        }),
        getSinappsGarantieList: build.query<CodeLabel[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.listeGarantiesSinappsDossierDetail,
            args,
          ),
        }),
        getSinappsCompensationModeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.modesIndemnisationSinappsList,
          ),
        }),
        /* Traveler - Recourse */
        getConventionList: build.query<CodeLabel[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.conventionsApplicablesDossierDetail,
            args,
          ),
        }),
        getLettreAcceptationList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.lettresAcceptationList,
          ),
        }),
        getIrsiTrancheList: build.query<TrancheIRSI[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.tranchesIrsiList,
          ),
        }),
        getIrsiBaremeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.baremesIrsiList,
          ),
        }),
        /* Traveler - Confidential */
        getConfidentialAnomalyList: build.query<QuestionREX[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.questionsRexList,
          ),
        }),
        /* Traveler - Payment */
        getPaymentTypeList: build.query<TypeReglement[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.typeReglementsList,
          ),
        }),
        /* Traveler - Irsi */
        getTravelerIrsi: build.query<Irsi, string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.irsiTravellerDetail,
            args,
          ),
          providesTags: (result, error, arg) => [
            { type: 'IRSI', id: result?.id || '' },
          ],
        }),
        postTravelerIrsi: build.mutation<boolean, Ditseuqered<{ query: IrsiTravellerCreateParams; data: IrsiRequest }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.irsiTravellerCreate,
            args.query,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'IRSI' ],
        }),
        getIrsiRiskAddressList: build.query<Adresse[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.adressesRisqueIrsiDossierDetail,
            args,
          ),
        }),
        getIrsiBuildingStatusList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.statutsImmeubleIrsiList,
          ),
        }),
        getIrsiPropertyUsageList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.usagesBienParAssureIrsiList,
          ),
        }),
        getIrsiDisasterNatureList: build.query<FamilleNatureSinistre[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.familleNaturesSinistreIrsiList,
          ),
        }),
        getIrsiDeleteCauseList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.causesSuppressionIrsiList,
          ),
        }),
        getIrsiCostingAgreementList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.accordsChiffrageList,
          ),
        }),
        getIrsiPersonQualifiantList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.qualifiantsPersonneIrsiList,
          ),
        }),
        getIrsiPersonRoleList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.rolesPersonneIrsiList,
          ),
        }),
        getIrsiInsuranceCompanyList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.assureursIrsiList,
          ),
        }),
        getIrsiExpertiseTypeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.typeExpertiseIrsiList,
          ),
        }),
        getIrsiQualitePersonList: build.query<CodeLabel[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.qualitesPersonneIrsiDetail,
            args,
          ),
        }),
        /* Traveler - Actions */
        postSinappsReport: build.mutation<boolean, Ditseuqered<{caseId: string; data: CommandeDeposerCompteRenduRequest}>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.deposerCompteRenduSinappsCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'PLANNINGS' ],
        }),
        getSinappsReportTodoList: build.query<ResteAFaire[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.restesAFaireList,
          ),
        }),
        getNotdepad: build.query<BlocNote, string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.blocNoteDossierDetail,
            args,
          ),
          providesTags: (result, error, arg) => [
            { type: 'NOTEPAD', id: '' },
          ],
        }),
        postNotepad: build.mutation<boolean, Ditseuqered<{caseId: string; data: BlocNoteDossierCreatePayload}>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.blocNoteDossierCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'NOTEPAD' ],
        }),
        /* Documents */
        getDocumentCategoryList: build.query<CategoriePJ[], CategoriesPjListParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.categoriesPjList,
            args,
          ),
        }),
        getCaseDocuments: build.query<PieceJointe[], DocumentDossierDetailParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.documentDossierDetail,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'MEDIAS', id: 'LIST' },
            ...result.map((r) => ({ type: 'MEDIAS' as const, id: `${arg.dossier}-${r.id}` })),
          ],
        }),
        getDocument: build.query<PieceJointe, string>({
          queryFn: async (args) => processRequest(
            OpenAPI.document.documentDetail,
            {
              id: args,
            },
          ),
          providesTags: (result, error, arg) => [
            { type: 'MEDIAS', id: result?.id || '' },
          ],
        }),
        postDocuments: build.mutation<void, Ditseuqered<{ caseId: string; data: DocumentDossierCreatePayload }>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.documentDossierCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'MEDIAS' ],
        }),
        deleteDocument: build.mutation<void, Ditseuqered<DocumentDeleteParams>>({
          queryFn: async (args) => processRequest(
            OpenAPI.document.documentDelete,
            args,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'MEDIAS' ],
        }),
        updateDocument: build.mutation<void, Ditseuqered<{id: string; data: UpdateFichierDocumentCreatePayload}>>({
          queryFn: async (args) => processRequest(
            OpenAPI.document.updateFichierDocumentCreate,
            args.id,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'MEDIAS' ],
        }),
        updateDocumentName: build.mutation<void, Ditseuqered<{id: string; data: UpdateDocumentRequest}>>({
          queryFn: async (args) => processRequest(
            OpenAPI.document.documentCreate,
            args.id,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'MEDIAS' ],
        }),
        /* Event Documents */
        getCaseEventDocuments: build.query<PieceJointe[], EvenementielDocumentDetailParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.evenementielDocumentDetail,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'EVENT_DOCUMENTS', id: 'LIST' },
            ...result.map((r) => ({ type: 'EVENT_DOCUMENTS' as const, id: `${arg.dossier}-${r.id}` })),
          ],
        }),
        getEventDocumentSfdt: build.query<DataDocument, string>({
          queryFn: async (args) => processRequest(
            OpenAPI.document.reeditionEvenementielDetail,
            args,
          ),
        }),
        getEventDocumentsFamilleActionList: build.query<FamilleAction[], FamilleEtActionsEvenementielDetailParams>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.familleEtActionsEvenementielDetail,
            args,
          ),
        }),
        getEventDocumentsRecipientList: build.query<EvenementielDestinataire[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.destinataireEvenementielDetail,
            args,
          ),
        }),
        postNewEventDocument: build.mutation<DataDocument, Ditseuqered<{dossier: string; data: Courrier}>>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.nouveauEvenementielCreate,
            args.dossier,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'EVENT_DOCUMENTS' ],
        }),
        postSaveEventDocument: build.mutation<void, Ditseuqered<{data: DataDocument}>>({
          queryFn: async (args) => processRequest(
            OpenAPI.document.enregistrementEvenementielCreate,
            args.data,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'EVENT_DOCUMENTS' ],
        }),
        postDeleteEventDocument: build.mutation<void, Ditseuqered<DocumentDeleteParams>>({
          queryFn: async (args) => processRequest(
            OpenAPI.document.documentDelete,
            args,
          ),
          invalidatesTags: (result, error) => error ? [] : [ 'EVENT_DOCUMENTS' ],
        }),
        /* Other SelectLists */
        getQualiteList: build.query<CodeLabel[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.qualitesPersonneDossierDetail,
            args,
          ),
        }),
        getApplicationGarantieList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.applicationGarantieList,
          ),
        }),
        getAssureTypeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.typeAssureList,
          ),
        }),
        getDisasterNatureList: build.query<NatureSinistre[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.naturesSinistreList,
          ),
        }),
        getCaseStateCodeList: build.query<CodeEtatDossier[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.codesEtatDossierList,
          ),
        }),
        getPersonRoleList: build.query<CodeLabel[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.rolesPersonneDossierDetail,
            args,
          ),
        }),
        getPersonQualifiantList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.qualifiantsPersonneList,
          ),
        }),
        getFamilleAnnuaireList: build.query<FamilleAnnuaire[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.famillesAnnuaireList,
          ),
        }),
        getTVAAssujettissementList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.assujettissementTvaList,
          ),
        }),
        getResponsabilityList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.responsabilitesList,
          ),
        }),
        getYesNoIndetermined: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.ouiNonIndetermineList,
          ),
        }),
        getUniteMesureList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.unitesMesureList,
          ),
        }),
        getTVARateList: build.query<TauxTVA[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.tauxTvaList,
          ),
        }),
        getPolitenessList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.formulesPolitesseList,
          ),
        }),
        getSinappsBienTypeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.typesBienSinappsList,
          ),
        }),
        getReportTypeList: build.query<CodeLabel[], string>({
          queryFn: async (args) => processRequest(
            OpenAPI.dossier.typesRapportDossierDetail,
            args,
          ),
        }),
        getCloseReportTypeList: build.query<CodeLabel[], void>({
          queryFn: async (args) => processRequest(
            OpenAPI.selectList.cloturesDossierList,
          ),
        }),
      }
    },
  },
)

/* Export hooks */
export const {
  // Auth
  useAuthenticateMutation,
  // Case
  useGetCasesByStatusQuery,
  useLazyGetCasesByStatusQuery,
  useGetCasesByFiltersQuery,
  useLazyGetCasesByFiltersQuery,
  useGetCasesChipListQuery,
  useLazyGetCasesChipListQuery,
  useGetCaseReportsQuery,
  useLazyGetCaseReportsQuery,
  useGetCaseTravelerQuery,
  useLazyGetCaseTravelerQuery,
  usePostCaseTravelerMutation,
  usePostNewReportMutation,
  // Sidebar
  useGetCaseWorkflowQuery,
  useLazyGetCaseWorkflowQuery,
  useGetCaseInfosQuery,
  useLazyGetCaseInfosQuery,
  useGetHistoriqueQuery,
  useLazyGetHistoriqueQuery,
  useGetSinappsPortalQuery,
  useLazyGetSinappsPortalQuery,
  useGetIrsiPortalQuery,
  useLazyGetIrsiPortalQuery,
  useGetEpassPortalQuery,
  useLazyGetEpassPortalQuery,
  // Notifications
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useGetNotificationCountQuery,
  useLazyGetNotificationCountQuery,
  // Planning
  useGetPlanningQuery,
  useLazyGetPlanningQuery,
  useGetPlanningTypesQuery,
  useLazyGetPlanningTypesQuery,
  usePostAddGeneralActionMutation,
  usePostEditGeneralActionMutation,
  // QA - Sinapps
  useGetSinappsRequestServiceEndReasonListQuery,
  useLazyGetSinappsRequestServiceEndReasonListQuery,
  useGetSinappsDifficultyStepListQuery,
  useLazyGetSinappsDifficultyStepListQuery,
  useGetSinappsDifficultyReasonListQuery,
  useLazyGetSinappsDifficultyReasonListQuery,
  useGetSinappsDifficultyListQuery,
  useLazyGetSinappsDifficultyListQuery,
  useGetSinappsDocumentTypeListQuery,
  useLazyGetSinappsDocumentTypeListQuery,
  useGetSinappsCloseDissatisfactionReasonListQuery,
  useLazyGetSinappsCloseDissatisfactionReasonListQuery,
  usePostSinappsRequestServiceEndMutation,
  usePostSinappsLiftServiceEndMutation,
  usePostSinappsSignalDifficultyMutation,
  usePostSinappsLiftDifficultyMutation,
  usePostSinappsDealDissatisfactionMutation,
  usePostSinappsCloseDissatisfactionMutation,
  // QA - Sinapps - Solicit Stakeholder
  useGetSinappsSolicitationListQuery,
  useLazyGetSinappsSolicitationListQuery,
  useGetSinappsSolicitInterventionTypeListQuery,
  useLazyGetSinappsSolicitInterventionTypeListQuery,
  useGetSinappsSolicitPrestationListQuery,
  useLazyGetSinappsSolicitPrestationListQuery,
  useGetSinappsSolicitDamageNatureListQuery,
  useLazyGetSinappsSolicitDamageNatureListQuery,
  useGetSinappsSolicitInterventionSubtypeListQuery,
  useLazyGetSinappsSolicitInterventionSubtypeListQuery,
  useGetSinappsSolicitOperationNatureListQuery,
  useLazyGetSinappsSolicitOperationNatureListQuery,
  useGetSinappsSolicitMandateEntityListQuery,
  useLazyGetSinappsSolicitMandateEntityListQuery,
  useGetSinappsSolicitCriticiteListQuery,
  useLazyGetSinappsSolicitCriticiteListQuery,
  useGetSinappsSolicitUrgencyReasonsListQuery,
  useLazyGetSinappsSolicitUrgencyReasonsListQuery,
  useGetSinappsSolicitConventionListQuery,
  useLazyGetSinappsSolicitConventionListQuery,
  useGetSinappsSolicitIrsiTrancheListQuery,
  useLazyGetSinappsSolicitIrsiTrancheListQuery,
  usePostSinappsSolicitStakeholderMutation,
  usePostSinappsCloseSolicitationMutation,
  useGetSinappsSolicitTypeListQuery,
  useLazyGetSinappsSolicitTypeListQuery,
  useGetSinappsSolicitStepListQuery,
  useLazyGetSinappsSolicitStepListQuery,
  useGetSinappsSolicitCorrectionReasonListQuery,
  useLazyGetSinappsSolicitCorrectionReasonListQuery,
  // QA - Sinapps - Vigilance Report
  usePostSinappsVigilanceReportMutation,
  useGetSinappsVigilanceReportListQuery,
  useLazyGetSinappsVigilanceReportListQuery,
  useDeleteSinappsVigilanceReportMutation,
  useGetSinappsVigilanceReportAlertQualificationListQuery,
  useLazyGetSinappsVigilanceReportAlertQualificationListQuery,
  useGetSinappsVigilanceReportDisasterContextListQuery,
  useLazyGetSinappsVigilanceReportDisasterContextListQuery,
  useGetSinappsVigilanceReportStakeholderBehaviourListQuery,
  useLazyGetSinappsVigilanceReportStakeholderBehaviourListQuery,
  useGetSinappsVigilanceReportCaseContextListQuery,
  useLazyGetSinappsVigilanceReportCaseContextListQuery,
  useGetSinappsVigilanceReportContractElementListQuery,
  useLazyGetSinappsVigilanceReportContractElementListQuery,
  useGetSinappsVigilanceReportExagerationListQuery,
  useLazyGetSinappsVigilanceReportExagerationListQuery,
  useGetSinappsVigilanceReportSupportingDocumentListQuery,
  useLazyGetSinappsVigilanceReportSupportingDocumentListQuery,
  // QA - Comment
  useGetCommentActionQuery,
  useLazyGetCommentActionQuery,
  usePostNewCommentMutation,
  // QA - Mail
  useGetMailSendersQuery,
  useLazyGetMailSendersQuery,
  useGetMailRecipientsQuery,
  useLazyGetMailRecipientsQuery,
  useGetMailTypeListQuery,
  useLazyGetMailTypeListQuery,
  useGetMailTypeQuery,
  useLazyGetMailTypeQuery,
  usePostNewMailMutation,
  // QA - Internal Exchanges
  useGetInternalExchangesQuery,
  useLazyGetInternalExchangesQuery,
  useGetInternalExchangesCountQuery,
  useLazyGetInternalExchangesCountQuery,
  useGetInternalExchangesRecipientListQuery,
  useLazyGetInternalExchangesRecipientListQuery,
  useGetInternalExchangesMessageTypeListQuery,
  useLazyGetInternalExchangesMessageTypeListQuery,
  usePostViewedInternalExchangeMutation,
  usePostNotViewedInternalExchangeMutation,
  usePostNewInternalExchangeMutation,
  usePostAnswerInternalExchangeMutation,
  usePostForwardInternalExchangeMutation,
  // QA - Task
  useGetTasksQuery,
  useLazyGetTasksQuery,
  useGetTaskCountQuery,
  useLazyGetTaskCountQuery,
  usePostTaskMutation,
  usePostCompleteTaskMutation,
  usePostPostponeTaskMutation,
  // Traveler - Actions
  usePostSinappsReportMutation,
  // Traveler - Stakeholders
  useGetStakeholderDirectoryListQuery,
  useLazyGetStakeholderDirectoryListQuery,
  useGetStakeholderFamilleAnnuaireListQuery,
  useLazyGetStakeholderFamilleAnnuaireListQuery,
  useGetTravelerStakeholderInfosQuery,
  useLazyGetTravelerStakeholderInfosQuery,
  // Traveler - Risk
  useGetRiskUsageListQuery,
  useLazyGetRiskUsageListQuery,
  useGetRiskTypeListQuery,
  useLazyGetRiskTypeListQuery,
  useGetRiskNotVerifiedReasonListQuery,
  useLazyGetRiskNotVerifiedReasonListQuery,
  useGetDetailBienRexListQuery,
  useLazyGetDetailBienRexListQuery,
  // Traveler - Disaster
  useGetDisasterSinappsNatureCauseDetailListQuery,
  useLazyGetDisasterSinappsNatureCauseDetailListQuery,
  useGetDisasterRexTypeListQuery,
  useLazyGetDisasterRexTypeListQuery,
  // Traveler - Damages
  useGetRoomTypeListQuery,
  useLazyGetRoomTypeListQuery,
  useGetNatureDetailBienListQuery,
  useLazyGetNatureDetailBienListQuery,
  useGetNatureBienListQuery,
  useLazyGetNatureBienListQuery,
  useGetReparabiliteListQuery,
  useLazyGetReparabiliteListQuery,
  useGetJustificationListQuery,
  useLazyGetJustificationListQuery,
  // Traveler - Compensation
  useGetCompensationDamagePositionListQuery,
  useLazyGetCompensationDamagePositionListQuery,
  useGetSinappsGarantieListQuery,
  useLazyGetSinappsGarantieListQuery,
  useGetSinappsCompensationModeListQuery,
  useLazyGetSinappsCompensationModeListQuery,
  // Traveler - Recourse
  useGetConventionListQuery,
  useLazyGetConventionListQuery,
  useGetLettreAcceptationListQuery,
  useLazyGetLettreAcceptationListQuery,
  useGetIrsiTrancheListQuery,
  useLazyGetIrsiTrancheListQuery,
  useGetIrsiBaremeListQuery,
  useLazyGetIrsiBaremeListQuery,
  // Traveler - Confidential
  useGetConfidentialAnomalyListQuery,
  useLazyGetConfidentialAnomalyListQuery,
  // Traveler - Payment
  useGetPaymentTypeListQuery,
  useLazyGetPaymentTypeListQuery,
  // Traveler - Irsi
  useGetTravelerIrsiQuery,
  useLazyGetTravelerIrsiQuery,
  useGetIrsiRiskAddressListQuery,
  useLazyGetIrsiRiskAddressListQuery,
  useGetIrsiBuildingStatusListQuery,
  useLazyGetIrsiBuildingStatusListQuery,
  useGetIrsiPropertyUsageListQuery,
  useLazyGetIrsiPropertyUsageListQuery,
  useGetIrsiDisasterNatureListQuery,
  useLazyGetIrsiDisasterNatureListQuery,
  useGetIrsiDeleteCauseListQuery,
  useLazyGetIrsiDeleteCauseListQuery,
  useGetIrsiCostingAgreementListQuery,
  useLazyGetIrsiCostingAgreementListQuery,
  useGetIrsiPersonQualifiantListQuery,
  useLazyGetIrsiPersonQualifiantListQuery,
  useGetIrsiPersonRoleListQuery,
  useLazyGetIrsiPersonRoleListQuery,
  useGetIrsiInsuranceCompanyListQuery,
  useLazyGetIrsiInsuranceCompanyListQuery,
  useGetIrsiExpertiseTypeListQuery,
  useLazyGetIrsiExpertiseTypeListQuery,
  useGetIrsiQualitePersonListQuery,
  useLazyGetIrsiQualitePersonListQuery,
  usePostTravelerIrsiMutation,
  // Documents
  useGetDocumentCategoryListQuery,
  useLazyGetDocumentCategoryListQuery,
  useGetCaseDocumentsQuery,
  useLazyGetCaseDocumentsQuery,
  useGetDocumentQuery,
  useLazyGetDocumentQuery,
  usePostDocumentsMutation,
  useDeleteDocumentMutation,
  useUpdateDocumentMutation,
  useUpdateDocumentNameMutation,
  // Event Documents
  useGetCaseEventDocumentsQuery,
  useLazyGetCaseEventDocumentsQuery,
  useGetEventDocumentSfdtQuery,
  useLazyGetEventDocumentSfdtQuery,
  useGetEventDocumentsFamilleActionListQuery,
  useLazyGetEventDocumentsFamilleActionListQuery,
  useGetEventDocumentsRecipientListQuery,
  useLazyGetEventDocumentsRecipientListQuery,
  usePostNewEventDocumentMutation,
  usePostSaveEventDocumentMutation,
  usePostDeleteEventDocumentMutation,
  // Traveler - Actions
  useGetSinappsReportTodoListQuery,
  useLazyGetSinappsReportTodoListQuery,
  useGetNotdepadQuery,
  useLazyGetNotdepadQuery,
  usePostNotepadMutation,
  // Other SelectLists
  useGetQualiteListQuery,
  useLazyGetQualiteListQuery,
  useGetApplicationGarantieListQuery,
  useLazyGetApplicationGarantieListQuery,
  useGetAssureTypeListQuery,
  useLazyGetAssureTypeListQuery,
  useGetDisasterNatureListQuery,
  useLazyGetDisasterNatureListQuery,
  useGetCaseStateCodeListQuery,
  useLazyGetCaseStateCodeListQuery,
  useGetPersonRoleListQuery,
  useLazyGetPersonRoleListQuery,
  useGetPersonQualifiantListQuery,
  useLazyGetPersonQualifiantListQuery,
  useGetFamilleAnnuaireListQuery,
  useLazyGetFamilleAnnuaireListQuery,
  useGetTVAAssujettissementListQuery,
  useLazyGetTVAAssujettissementListQuery,
  useGetResponsabilityListQuery,
  useLazyGetResponsabilityListQuery,
  useGetYesNoIndeterminedQuery,
  useLazyGetYesNoIndeterminedQuery,
  useGetUniteMesureListQuery,
  useLazyGetUniteMesureListQuery,
  useGetTVARateListQuery,
  useLazyGetTVARateListQuery,
  useGetPolitenessListQuery,
  useLazyGetPolitenessListQuery,
  useGetCauseNotDeletedReasonListQuery,
  useLazyGetCauseNotDeletedReasonListQuery,
  useGetSinappsBienTypeListQuery,
  useLazyGetSinappsBienTypeListQuery,
  useGetReportTypeListQuery,
  useLazyGetReportTypeListQuery,
  useGetCloseReportTypeListQuery,
  useLazyGetCloseReportTypeListQuery,
} = api

/* Add store to the window */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.api = api
