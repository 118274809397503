/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import {
  MoreVertRounded,
  SubdirectoryArrowRightRounded,
} from '@mui/icons-material'
import BlueRoundedChip from 'components/BlueRoundedChip/BlueRoundedChip'

/* Styled components -------------------------------------------------------- */
const ChipContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-self: right;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export interface GridContainerProps {
  sub?: boolean;
}

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: ${(props) => props.sub ? '35px' : null } auto 3fr 30px;
  align-items: end;
  margin-top: 15px;
`

const RefPoliceContainer = styled.div`
  display: flex;
  gap: 10px;
`

const TelContainer = styled.div`
  a {
    margin-right: 10px;
  }
`

const BoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  margin-top: 20px;
`

const LightSeparator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  margin-top: 20px;
`

const Chip = styled(BlueRoundedChip)`
  margin-bottom: 5px;
  margin-left: 10px;
`

const MoreDotsButton = styled(Button)`
  min-width: auto;
  margin-left: 5px;
`

const MoreDots = styled(MoreVertRounded)`
  font-size: 2.5rem;
  color: ${(props) => props.theme.palette.primary.main};
`

const SubIcon = styled(SubdirectoryArrowRightRounded)`
  font-size: 30px;
  color: ${(props) => props.theme.palette.secondary.main};
`

const RepresenteParContainer = styled.div`
  padding: 0px 20px;
`

export default {
  ChipContainer,
  GridContainer,
  RefPoliceContainer,
  TelContainer,
  LightSeparator,
  BoldSeparator,
  Chip,
  MoreDotsButton,
  MoreDots,
  SubIcon,
  RepresenteParContainer,
}
