/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import KeyboardArrowRightRounded from '@mui/icons-material/KeyboardArrowRightRounded'

/* Styled components -------------------------------------------------------- */
const TitleButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  padding-left: 0px;
  margin-top: 10px;
  svg {
    font-size: 2rem !important;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarArrowTitleProps {
  title: string;
  setOpenedMenu: () => void;
}

const CaseSidebarArrowTitle: React.FC<CaseSidebarArrowTitleProps> = ({
  title,
  setOpenedMenu,
}) => {

  return (
    <TitleButton
      variant="text"
      color="secondary"
      onClick={setOpenedMenu}
      endIcon={<KeyboardArrowRightRounded />}
      fullWidth
    >
      {title}
    </TitleButton>
  )
}

export default CaseSidebarArrowTitle
