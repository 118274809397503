/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component declaration ---------------------------------------------------- */
const CaseItemBarHorizontal = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey};
  margin: 10px 0px;
`

export default CaseItemBarHorizontal
