/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import PriceDiv from 'components/FieldWithInputAdornment/PriceDiv'
import CompensationTableRow from './CompensationTableComponents/CompensationTableRow'
import CompensationTableBoldUppercaseTitle from './CompensationTableComponents/CompensationTableBoldUppercaseTilte'
import CompensationTableBoldSeparator from './CompensationTableComponents/CompensationTableBoldSeparator'
import CompensationPersonTableCard from './CompensationTableComponents/CompensationPersonTableCard'

/* Type imports ------------------------------------------------------------- */
import type { DommageBienSinapps } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const TableDommageBienSinappsRow = styled(CompensationTableRow)`
  grid-template-columns: repeat(5, 10%) repeat(2, 12%) 12%;
  gap: 2%;
`

const Title = styled(FormBoldTitle)`
  margin-top: 10px;
  margin-bottom: -20px;
`

/* Component declaration ---------------------------------------------------- */
interface CompensationPersonSinappsPropertyDamageTableProps {
  personIndexName: string;
  dommagesBienSinapps: DommageBienSinapps[];
  isHT: boolean;
  disabled: boolean;
  handleValue: (type: string, value: string) => void;
}

const CompensationPersonSinappsPropertyDamageTable: React.FC<CompensationPersonSinappsPropertyDamageTableProps> = ({
  personIndexName,
  dommagesBienSinapps,
  isHT,
  disabled,
  handleValue,
}) => {

  return (
    <>
      <FormBoldTitle bigger>
        Récapitulatif des domages bien Sinapps
      </FormBoldTitle>
      <CompensationPersonTableCard>
        <CompensationTableBoldUppercaseTitle>
          <TableDommageBienSinappsRow>
            <div>
              Descriptif
            </div>
            <div>
              Valeur à neuf / Estimation
            </div>
            <div>
              Date d'achat
            </div>
            <div>
              Montant vetusté
            </div>
            <div>
              Plafond vétusté rec.
            </div>
            <div>
              Réparabilité
            </div>
            <div>
              Justification
            </div>
            <div>
              Plafond
            </div>
          </TableDommageBienSinappsRow>
        </CompensationTableBoldUppercaseTitle>
        <CompensationTableBoldSeparator />
        {
          dommagesBienSinapps.map((dommage, index) => (
            <div key={`${dommage.id}-${index}`}>
              <Title>
                {dommage.natureDuBienSinapps?.libelle || dommage.natureDuBienSinapps?.code}
              </Title>
              <TableDommageBienSinappsRow border={index !== (dommagesBienSinapps?.length || 0) -1}>
                <div>
                  {dommage.libelle}
                </div>
                <PriceDiv price={isHT ? dommage.montantHT : dommage.montantTTC} />
                <div>
                  {DateUtils.APIStrToLocalDateString(dommage.dateAchat)}
                </div>
                <PriceDiv price={isHT ? dommage.montantVetusteHT : dommage.montantVetusteTTC} />
                <PriceDiv price={isHT ? dommage.montantVetusteRecuperableHT : dommage.montantVetusteRecuperableTTC} />
                <div>
                  {dommage.reparabilite?.libelle || dommage.reparabilite?.code}
                </div>
                <div>
                  {dommage.justificatif?.libelle || dommage.justificatif?.code}
                </div>
                <PriceField
                  placeholder="Plafond"
                  size="small"
                  value={dommage.plafond || ''}
                  name={`${personIndexName}.dommagesBienSinapps.[${index}].plafond`}
                  onChange={(e) => handleValue(`${personIndexName}.dommagesBienSinapps.[${index}].plafond`, e.target.value)}
                  disabled={disabled}
                />
              </TableDommageBienSinappsRow>
            </div>
          ))
        }
        <CompensationTableBoldSeparator />
        <FormBoldTitle smaller>
          <TableDommageBienSinappsRow>
            Totaux
            <PriceDiv price={dommagesBienSinapps.reduce((a, b) => a + Number(isHT ? b.montantHT : b.montantTTC), 0)} />
            <div />
            <PriceDiv price={dommagesBienSinapps.reduce((a, b) => a + Number(isHT ? b.montantVetusteHT : b.montantVetusteTTC), 0)} />
            <PriceDiv price={dommagesBienSinapps.reduce((a, b) => a + Number(isHT ? b.montantVetusteRecuperableHT : b.montantVetusteRecuperableTTC), 0)} />
            <div />
            <div />
            <PriceDiv price={dommagesBienSinapps.reduce((a, b) => a + Number(b.plafond || 0), 0)} />
          </TableDommageBienSinappsRow>
        </FormBoldTitle>
      </CompensationPersonTableCard>
    </>
  )
}

export default CompensationPersonSinappsPropertyDamageTable
