/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Checkbox,
  FormControlLabel,
  Collapse,
  Card,
  CardContent,
} from '@mui/material'
import {
  AddRounded,
  Check,
} from '@mui/icons-material'

/* Styled components -------------------------------------------------------- */
interface CheckedProps {
  checked: boolean;
}

const CardContainer = styled(Card)<CheckedProps>`
  margin-top: 20px;
  margin-bottom: 20px;
  border: solid ${(props) => props.checked ? `2px ${props.theme.palette.primary.main}` : `1px ${props.theme.colors.grey}`} !important;

  &:hover {
    filter: brightness(95%);
  }
`

const CardTitleContainer = styled(CardContent)`
  cursor: pointer;
  padding: 0px 10px !important;
`

const CheckableFormControlLabel = styled(FormControlLabel)`
  color: ${(props) => props.theme.palette.primary.main};
`

const CardContentContainer = styled(CardContent)`
  padding-top: 0;
`
interface CheckableFormControlLabelProps {
  checked: boolean;
  disabled: boolean;
}

const CustomCheckbox = styled(Checkbox)<CheckableFormControlLabelProps>`
  height: 38px;
  width: 38px;

  svg {
    fill: ${(props) => props.checked ? props.theme.palette.primary.main : props.disabled ? props.theme.palette.info.dark : props.theme.palette.primary.main};
  }
`

const AddIcon = styled(AddRounded)`
  font-size: 28px;
  margin-right: -3px;
`

/* Component declaration ---------------------------------------------------- */
interface CheckableCollapseCardProps {
  checked: boolean;
  onChange: () => void;
  label: React.ReactNode;
  children: React.ReactNode;
  addType?: boolean;
  disabled?: boolean;
}

const CheckableCollapseCard: React.FC<CheckableCollapseCardProps> = ({
  checked,
  onChange,
  label,
  children,
  addType = false,
  disabled = false,
}) => {

  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault()
    onChange()
  }

  return (
    <CardContainer checked={checked}>
      <CardTitleContainer onClick={handleClick}>
        <CheckableFormControlLabel
          control={
            <CustomCheckbox
              icon={addType ? <AddIcon /> : undefined}
              checked={checked}
              checkedIcon={<Check />}
              disabled={disabled}
            />
          }
          label={label}
          checked={checked}
          disabled={disabled}
        />
      </CardTitleContainer>
      <Collapse
        in={checked}
        timeout="auto"
        unmountOnExit
      >
        <CardContentContainer>
          {children}
        </CardContentContainer>
      </Collapse>
    </CardContainer>
  )
}

export default CheckableCollapseCard
