/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useAppDispatch } from 'store/hooks'
import { openMediaLibrary } from 'store/slices/mediaLibrarySlice'
import { isEditableFileType } from 'helpers/getMediaFileType'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
} from '@mui/material'
import ImportAttachmentModal from 'components/ImportAttachmentModal/ImportAttachmentModal'
import DrawerContent from 'components/Drawer/DrawerContent'
import DocumentNameEditorForm from 'components/MediaRenderer/DocumentNameEditorForm'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'

/* Type imports ------------------------------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'
import { openFileInNewWindow } from 'helpers/offlineMediaHelper'

/* Styled components -------------------------------------------------------- */
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 15px;
  padding: 10px 10px 0px 20px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarMediaMenuProps {
  isFetching: boolean;
  documents: PieceJointe[];
  onClose: () => void;
  caseId: string;
  isOnline: boolean;
}

const CaseSidebarMediaMenu: React.FC<CaseSidebarMediaMenuProps> = ({
  isFetching,
  documents,
  onClose,
  caseId,
  isOnline,
}) => {
  const [ files, setFiles ] = useState<File[]>([])
  const [ open, setOpen ] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  if (isFetching) {
    return <CircularProgress />
  }

  const handleCloseModal = (): void => {
    setOpen(false)
    setFiles([])
  }

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      setFiles([ ...Object.values(e.target.files || {}) ])
      setOpen(true)
    }
  }

  const handleMediaClick = (document: PieceJointe): void => {
    if (isEditableFileType(document)) {
      dispatch(openMediaLibrary(document))
      onClose()
    } else if (isValidString(document.url) && isValidString(document.id)) {
      openFileInNewWindow(isOnline, document.id || '', document.url)
    }
  }

  return (
    <>
      <TitleContainer>
        Médias
        <AttachmentButton
          onChange={handleOnFileChange}
          disabled={!isOnline}
        >
          <Button
            variant="contained"
            component="span"
            disabled={!isOnline}
          >
            Ajouter une PJ
          </Button>
        </AttachmentButton>
      </TitleContainer>
      <DrawerContent>
        {
          documents.map((document, index) => (
            <DocumentNameEditorForm
              document={document}
              onEdition={() => handleMediaClick(document)}
              key={`${document.fileName}-${index}`}
            />
          ))
        }
      </DrawerContent>
      {
        files.length > 0 && isOnline && open &&
          <ImportAttachmentModal
            handleClose={handleCloseModal}
            files={files}
            setFiles={setFiles}
            caseId={caseId}
          />
      }
    </>
  )
}

export default CaseSidebarMediaMenu
