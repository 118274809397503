/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import DrawerContent from 'components/Drawer/DrawerContent'
import BlueRoundedChip from 'components/BlueRoundedChip/BlueRoundedChip'
import ScrollableFiltersContainer from 'components/ScrollableFiltersContainer/ScrollableFiltersContainer'
import CaseSidebarHistoryItem from './CaseSidebarHistoryItem'

/* Type imports ------------------------------------------------------------- */
import type {
  SuiviSimplifie,
  TypeSuiviEnumLabel,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 15px;
  height: 46.5px;
  padding: 10px 10px 0px 20px;
`

const Chip = styled(BlueRoundedChip)`
  min-width: fit-content;
  height: fit-content;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarHistoryMenuProps {
  items?: SuiviSimplifie[];
  isFetching: boolean;
  isOnline: boolean;
}

const CaseSidebarHistoryMenu: React.FC<CaseSidebarHistoryMenuProps> = ({
  items = [],
  isFetching,
  isOnline,
}) => {
  const [ filters, setFilters ] = useState<TypeSuiviEnumLabel[]>([])

  const chipTypes = useMemo(() => [ ...new Map(items.map((items) => items.type).map((v) => [ v.libelle, v ])).values() ].sort((a, b) => a.code.localeCompare(b.code)), [ items ])

  const handleFilter = (newType: TypeSuiviEnumLabel): void => {
    if (filters.find((type) => type.code === newType.code)) {
      setFilters([ ...filters.filter((type) => type.code !== newType.code) ])
    } else {
      setFilters([ ...filters, newType ])
    }
  }

  return (
    <>
      <TitleContainer>
        Historique
      </TitleContainer>
      <DrawerContent>
        <ScrollableFiltersContainer>
          {
            chipTypes.map((type) => (
              <Chip
                variant={filters.find((filter) => filter.code === type.code) ? 'contained' : 'outlined'}
                key={type.code}
                onClick={() => handleFilter(type)}
              >
                {type.libelle}
              </Chip>
            ))
          }
        </ScrollableFiltersContainer>
        {
          isFetching ?
            <CircularProgress /> :
            items
              .filter((item) => filters.length === 0 || filters.find((filter) => filter.code === item.type.code))
              .map((item: SuiviSimplifie, index: number) => (
                <CaseSidebarHistoryItem
                  key={`history-${item.libelle}-${index}`}
                  item={item}
                  strLenLimit={180}
                  isOnline={isOnline}
                />
              ))
        }
      </DrawerContent>
    </>
  )
}

export default CaseSidebarHistoryMenu
