/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
} from '@mui/material'
import CompleteTaskModal from 'pages/TasksPage/TasksComponents/CompleteTaskModal/CompleteTaskModal'
import PostponeTaskModal from 'pages/TasksPage/TasksComponents/PostponeTaskModal/PostponeTaskModal'

/* Type imports ------------------------------------------------------------- */
import type { Tache } from 'API/__generated__/Api'
import { TypeTache } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
`

const DateContainer = styled.div`
  display: inline;
  float: right;
`

const Line = styled.h5`
  font-weight: normal;
  margin: 5px 0px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarTaskMenuMessageProps {
  task: Tache;
  isOnline: boolean;
}

const CaseSidebarTaskMenuMessage: React.FC<CaseSidebarTaskMenuMessageProps> = ({ task, isOnline }) => {
  const [ openModal, setOpenModal ] = useState<'complete' | 'postpone' | ''>('')

  return (
    <CardContainer>
      <CardContent>
        <Line>
          <b>
            {task.libelle}
          </b>
          <DateContainer>
            {DateUtils.APIStrToLocalDateString(task.dateCreation)}
          </DateContainer>
        </Line>
        {
          isValidString(task.prevuPour) && (
            <Line>
              {'Échéance : '}
              <b>
                {DateUtils.APIStrToLocalDateString(task.prevuPour)}
              </b>
            </Line>
          )
        }
        <ButtonsContainer>
          <Button
            variant="contained"
            onClick={() => setOpenModal('complete')}
            fullWidth
            disabled={!isOnline}
          >
            Solder
          </Button>
          {
            task.typeTache !== TypeTache.Relance &&
              <Button
                variant="outlined"
                onClick={() => setOpenModal('postpone')}
                fullWidth
                disabled={!isOnline}
              >
                Reporter
              </Button>
          }
        </ButtonsContainer>
      </CardContent>
      {
        openModal === 'complete' &&
          <CompleteTaskModal
            handleClose={() => setOpenModal('')}
            task={task}
          />
      }
      {
        openModal === 'postpone' &&
          <PostponeTaskModal
            handleClose={() => setOpenModal('')}
            task={task}
          />
      }
    </CardContainer>
  )
}

export default CaseSidebarTaskMenuMessage
