/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useMatch,
  useNavigate,
} from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import { Badge } from '@mui/material'
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined'

/* Styled components -------------------------------------------------------- */
const BadgeContainer = styled(Badge)`
  span.MuiBadge-badge {
    margin-top: 4px;
  }
`

interface NavBarButtonProps {
  isActive?: boolean;
}

const NavBarButton = styled.button<NavBarButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${(props) => props.isActive ? props.theme.palette.info.main : '#8287AE'};
  background: none;
  border: none;

  svg {
    height: 24px;
  }

  &:hover {
    cursor: pointer;
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    svg {
      height: 16px;
    }
  }
`

const NavBarActiveIndicator = styled.div<NavBarButtonProps>`
  position: absolute;
  top: -12px;
  background-color: ${(props) => props.theme.palette.info.main};
  border-radius: 50vh;
  width: 100%;
  height: 6px;
  max-width: 100px;
  display: ${(props) => props.isActive ? 'block' : 'none'};
  z-index: 1000;

  @media ${(props) => props.theme.media.mobile.portrait} {
    top: -13px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface NavBarItemButtonProps {
  to: string;
  Icon?: React.ReactNode;
  title: string;
  notificationCount: number;
}

const NavBarItemButton: React.FC<NavBarItemButtonProps> = ({
  to,
  Icon = <HelpCenterOutlinedIcon />,
  title,
  notificationCount,
}) => {
  const navigate = useNavigate()

  const matchResult = useMatch(
    {
      path: to,
      caseSensitive: true,
      end: false,
    },
  )

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    navigate(to)
  }

  return (
    <BadgeContainer
      badgeContent={notificationCount}
      invisible={notificationCount === 0}
      color="error"
      anchorOrigin={
        {
          vertical: 'top',
          horizontal: 'right',
        }
      }
    >
      <NavBarButton
        isActive={matchResult !== null}
        onClick={onClick}
        id={title}
      >
        <NavBarActiveIndicator isActive={matchResult !== null} />
        {Icon}
        {title}
      </NavBarButton>
    </BadgeContainer>
  )
}

export default NavBarItemButton
