/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import {
  useLazyGetCaseEventDocumentsQuery,
  usePostSaveEventDocumentMutation,
} from 'store/api'
import {
  getCourriers,
  setAttachment,
} from 'store/slices/courrierSlice'
import { isApiResponse } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import MediaProcessorFallback from 'components/MediaProcessors/MediaProcessorFallback/MediaProcessorFallback'

/* Type imports ------------------------------------------------------------- */
import type { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor'
import type { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet'
import type { ApiResponse } from 'helpers/fetchHelpers'
import type { DataDocument } from 'API/__generated__/Api'
import {
  DataDocumentType,
  TypeEnregistrementCourrier,
} from 'API/__generated__/Api'

/* Lazy component imports --------------------------------------------------- */
const WordProcessor = React.lazy(() => import(/* webpackPrefetch: true */ 'components/MediaProcessors/WordProcessor/WordProcessor' /* webpackChunkName: "word" */))
const SpreadsheetProcessor = React.lazy(() => import(/* webpackPrefetch: true */ 'components/MediaProcessors/SpreadsheetProcessor/SpreadSheetProcessor' /* webpackChunkName: "spreadsheet" */))

/* Styled components -------------------------------------------------------- */
const WordPageContainer = styled(PageContainer)`
  padding: 0px 5px 7px 0px !important;
  margin: -10px 0px 0px -10px;
  overflow: hidden;
`

interface CollapseContainerProps {
  top: number;
}

const CollapseContainer = styled(Collapse)<CollapseContainerProps>`
  position: absolute;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - ${(props) => props.top}px);
  z-index: 204;
  top: ${(props) => props.top}px;
  background-color: white;
  padding: 0px !important;
`

const CourrierItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  background-color: white;
  width: 100%;
  height: 50px;
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: #96b0f926;
  }
`

const ClickableTitle = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  display: flex;

  &:hover {
    background-color: #96b0f926;
    border-radius: 4px;
  }
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  font-size: 30px;
  margin-top: -3px;
  transform: scaleY(${(props) => props.open ? '-1' : 1});
  transition: transform .2s;
`

const DialogTitleContainer = styled(DialogTitle)`
  display: flex;
  justify-content: center;
`

const DialogContentContainer = styled(DialogContent)`
  display: flex;
  gap: 10px;
  justify-content: center;
`

/* Component declaration ---------------------------------------------------- */
interface DocumentProcessorPageProps {}

const DocumentProcessorPage: React.FC<DocumentProcessorPageProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { caseId = '' } = useParams<{caseId: string}>()
  const courriers: DataDocument[] = useAppSelector(getCourriers)
  const [ wordRef, setWordRef ] = useState<DocumentEditorContainerComponent | null>(null)
  const [ spreadSheetRef, setSpreadsheetRef ] = useState<SpreadsheetComponent | null>(null)
  const [ isSaving, setIsSaving ] = useState<boolean>(false)
  const [ isOpen, setIsOpen ] = useState<boolean>(false)
  const [ selected, setSelected ] = useState<number>(0)
  const [ editedCourriers, setEditedCourriers ] = useState<DataDocument[]>(courriers || [])
  const [ openAfterSaveModal, setOpenAfterSaveModal ] = useState<boolean>(false)
  const [ countForSending, setCountForSending ] = useState<number>(0)

  const [
    saveDocument,
  ] = usePostSaveEventDocumentMutation()
  const [ getDocuments ] = useLazyGetCaseEventDocumentsQuery()

  useEffect(() => {
    setEditedCourriers(courriers || [])
  }, [ courriers ])

  const docType = useMemo(() => {
    if (editedCourriers[selected]?.dataDocumentType === DataDocumentType.SpreadSheet || courriers[selected]?.dataDocumentType === DataDocumentType.SpreadSheet) {
      return 'excel'
    }
    return 'word'
  }, [ editedCourriers[selected]?.dataDocumentType, courriers[selected]?.dataDocumentType, selected ])

  useEffect(() => {
    if (editedCourriers[selected]?.jsonData || courriers[selected]?.jsonData) {
      if (docType === 'excel') {
        spreadSheetRef?.openFromJson({ file: editedCourriers[selected].jsonData || courriers[selected]?.jsonData })
      } else {
        wordRef?.documentEditor?.open(editedCourriers[selected].jsonData || courriers[selected]?.jsonData)
      }
    }
  }, [ courriers, wordRef?.documentEditor, selected, spreadSheetRef ])

  const onSelectChange = (newValue: number) => {
    setEditedCourriers([ ...editedCourriers.map((courrier, index) => index === selected ? { ...courrier, jsonData: wordRef?.documentEditor.serialize() || '' } : courrier) ])
    setSelected(newValue)
    setIsOpen(false)
  }

  const onSave = async (saveType: TypeEnregistrementCourrier) => {
    for (let index = 0; index < editedCourriers.length; index++) {
      let jsonData = wordRef?.documentEditor.serialize() || ''
      if (docType === 'excel') {
        jsonData = JSON.stringify(await spreadSheetRef?.saveAsJson())
      }
      const response: ApiResponse<void> = await saveDocument({
        data: {
          ...editedCourriers[index],
          docId: editedCourriers[index].docId,
          typeEnregistrement: saveType,
          jsonData: index === selected ? jsonData : editedCourriers[index].jsonData,
        },
      })
      if (isApiResponse<void>(response)) {
        toast.success(`Le document ${editedCourriers[index].docId} à bien été enregistré ${saveType === TypeEnregistrementCourrier.Definitif ? 'en définitif' : 'à la frappe'}.`)
        if (saveType === TypeEnregistrementCourrier.Definitif) {
          setCountForSending(countForSending + 1)
        }
      } else {
        toast.error(`Une erreur est survenue lors de l'enregistrement du document ${editedCourriers[index].docId}.`)
      }
    }
  }

  useEffect(() => {
    if (countForSending === courriers.length) {
      setOpenAfterSaveModal(true)
    }
  }, [ countForSending ])

  const onSubmit = (saveType: TypeEnregistrementCourrier) => {
    setIsSaving(true)
    onSave(saveType).catch(console.error).finally(() => setIsSaving(false))
  }

  const leaveClick = () => {
    setOpenAfterSaveModal(false)
    navigate(-1)
  }

  const sendClick = async () => {
    setOpenAfterSaveModal(false)
    const documents = (await getDocuments({ dossier: caseId })).data
    if (documents?.length) {
      dispatch(setAttachment(documents.filter(((doc) => courriers.find((courrier) => courrier.docId === doc.idDocEvenementiel)))))
      navigate(`/dossiers/${caseId}/traveller/actions/communication`)
    }
  }

  const headerHeight = useMemo(() => document.getElementById('document-processor-id')?.offsetTop || 180, [])

  return (
    <>
      <>
        <HeaderAction
          title={
            <ClickableTitle onClick={() => setIsOpen(!isOpen)}>
              {`Document ${courriers[selected]?.docId || ''}`}
              {courriers.length > 1 && <DropDownArrow open={isOpen} />}
            </ClickableTitle>
          }
          onSubmit={() => {}}
        >
          <SubmitFormButton
            variant="contained"
            disabled={isSaving}
            onClick={() => onSubmit(TypeEnregistrementCourrier.Frappe)}
          >
            {courriers.length > 1 ? 'Tout enregistrer à la frappe': 'Enregistrer à la frappe'}
          </SubmitFormButton>
          <SubmitFormButton
            variant="contained"
            disabled={isSaving}
            onClick={() => onSubmit(TypeEnregistrementCourrier.Definitif)}
          >
            {courriers.length > 1 ? 'Tout enregistrer en définitif': 'Enregistrer en définitif'}
          </SubmitFormButton>
        </HeaderAction>
        <div id="document-processor-id" />
        <CollapseContainer
          in={isOpen}
          timeout="auto"
          unmountOnExit
          top={headerHeight}
        >
          {
            courriers.length > 1 && courriers.map((courrier, index) =>
              index !== selected &&
                <CourrierItem
                  key={`courrier-${courrier.docId}-${index}`}
                  onClick={() => onSelectChange(index)}
                >
                  {courrier.docId}
                </CourrierItem>,
            )
          }
        </CollapseContainer>
      </>
      {
        docType === 'excel' ?
          <MediaProcessorFallback mediaType="excel">
            <SpreadsheetProcessor
              isLoading={isSaving}
              setSpreadsheetRef={setSpreadsheetRef}
              spreadsheetRef={spreadSheetRef}
              disabled={isSaving}
            />
          </MediaProcessorFallback> :
          <WordPageContainer>
            <MediaProcessorFallback mediaType="word">
              <WordProcessor
                enableToolbar
                isLoading={isSaving}
                wordRef={wordRef}
                setWordRef={setWordRef}
                disabled={isSaving}
              />
            </MediaProcessorFallback>
          </WordPageContainer>
      }
      {
        openAfterSaveModal &&
          <Dialog
            open
            maxWidth="xs"
            fullWidth
          >
            <DialogTitleContainer>
              Voulez-vous envoyez les documents ?
            </DialogTitleContainer>
            <DialogContentContainer>
              <SubmitFormButton
                onClick={sendClick}
                variant="contained"
              >
                Envoyer
              </SubmitFormButton>
              <SubmitFormButton
                onClick={leaveClick}
                variant="outlined"
              >
                Quitter
              </SubmitFormButton>
            </DialogContentContainer>
          </Dialog>
      }
    </>
  )
}

export default DocumentProcessorPage
