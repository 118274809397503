/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  CircularProgress,
  Tooltip,
} from '@mui/material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import CaseItemButtonsContainer from '../CaseItemButtonsContainer/CaseItemButtonsContainer'

/* Type imports ------------------------------------------------------------- */
import type { Pastille } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CaseTitleRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CaseTitleContainer = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  flex-direction: column;
`

const ChipsContainer = styled.div`
  display: flex;
  gap: 5px;
`

const TooltipText = styled.div`
  font-size: 15px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseItemTitleContainerProps {
  disabled?: boolean;
  caseId: string;
  assure: string;
  reportObjective?: string | null;
  company: string;
  caseChips?: Pastille[];
  isFetchingChip: boolean;
  disableCasePageButton?: boolean;
}

const CaseItemTitleContainer: React.FC<CaseItemTitleContainerProps> = ({
  caseId,
  disabled,
  assure,
  reportObjective,
  company,
  caseChips = [],
  isFetchingChip,
  disableCasePageButton,
}) => {

  return (
    <CaseTitleRowContainer>
      <CaseTitleContainer>
        {`Dossier ${caseId} - ${assure}`}
        <ChipsContainer>
          {
            isFetchingChip ?
              <CircularProgress size={20} /> :
              caseChips.map((chip, index) => (
                <Tooltip
                  key={`${chip.tooltip}-${index}`}
                  placement="top-end"
                  arrow
                  title={
                    <TooltipText>
                      {chip.tooltip}
                    </TooltipText>
                  }
                >
                  <ColoredSquareChip
                    color="salmon"
                    customColor={chip.couleur}
                    smaller
                  >
                    {chip.typePastille?.libelle}
                  </ColoredSquareChip>
                </Tooltip>
              ))
          }
        </ChipsContainer>
      </CaseTitleContainer>
      <CaseItemButtonsContainer
        caseId={caseId}
        disabled={disabled}
        reportObjective={reportObjective}
        company={company}
        disableCasePageButton={disableCasePageButton}
      />
    </CaseTitleRowContainer>
  )
}

export default CaseItemTitleContainer
