/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import {
  usePostViewedInternalExchangeMutation,
  usePostNotViewedInternalExchangeMutation,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
} from '@mui/material'
import CaseItemTitleContainer from 'components/CasesList/CaseItem/CaseItemTitleContainer/CaseItemTitleContainer'
import ClickableColoredSquareChip from 'components/ColoredSquareChip/ClickableColoredSquareChip'
import InternalExchangeAnswerForward from 'components/InternalExchange/InternalExchangeAnswerForward'

/* Type imports ------------------------------------------------------------- */
import type {
  EchangeInterne,
  Pastille,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardTitleContainer = styled(CardContent)`
  padding: 10px 5px 0px 10px;
`

const BoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  margin-top: 10px;
`

const BoldText = styled.div`
  font-weight: bold;
  display: inline;
`

const ObjectTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`

const DateContainer = styled.div`
  display: flex;
  height: fit-content;
  align-items: center;
  gap: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface InternalExchangesMessageProps {
  exchange: EchangeInterne;
  caseChips?: Pastille[];
  isFetchingChip: boolean;
}

const InternalExchangesMessage: React.FC<InternalExchangesMessageProps> = ({
  exchange,
  caseChips = [],
  isFetchingChip,
}) => {
  const [
    submitViewed,
    { isLoading: isSubmittingViewed },
  ] = usePostViewedInternalExchangeMutation()

  const [
    submitNotViewed,
    { isLoading: isSubmittingNotViewed },
  ] = usePostNotViewedInternalExchangeMutation()

  const handleChipClick = (): void => {
    if (isSubmittingNotViewed || isSubmittingViewed) return
    if (exchange.lu) {
      submitNotViewed({ id: exchange.id }).catch(console.error)
    } else {
      submitViewed({ id: exchange.id }).catch(console.error)
    }
  }

  return (
    <Card>
      <CardTitleContainer>
        <CaseItemTitleContainer
          caseId={exchange.dossier.id}
          assure={exchange.dossier.nomAssure}
          reportObjective={exchange.dossier.objectifCharteRapport}
          company={exchange.dossier.mandant}
          caseChips={caseChips}
          isFetchingChip={isFetchingChip}
        />
      </CardTitleContainer>
      <BoldSeparator />
      <CardContent>
        <ObjectTitle>
          <div>
            <div>
              {'De : '}
              <BoldText>
                {exchange.emetteur.nom}
              </BoldText>
            </div>
            <div>
              {'Objet : ' }
              <BoldText>
                {exchange.objet}
              </BoldText>
            </div>
          </div>
          <DateContainer>
            {DateUtils.APIStrToLocalDateString(exchange.date, { hour: '2-digit', minute: '2-digit' })}
            <ClickableColoredSquareChip
              color={exchange.lu ? 'green' : 'red'}
              onClick={handleChipClick}
            >
              {exchange.lu ? 'Lu' : 'Nouveau'}
            </ClickableColoredSquareChip>
          </DateContainer>
        </ObjectTitle>
        {exchange.message}
        <InternalExchangeAnswerForward exchange={exchange} />
      </CardContent>
    </Card>
  )
}

export default InternalExchangesMessage
