/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useGetCasesChipListQuery,
  useGetCaseStateCodeListQuery,
  useGetDisasterNatureListQuery,
  useGetTasksQuery,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { isValidString } from 'helpers/isValidString'
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  CircularProgress,
  MenuItem,
  Select,
} from '@mui/material'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderWithSearch from 'layouts/MainLayout/Headers/HeaderWithSearch'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import PaginationButtons from 'components/PaginationButtons/PaginationButtons'
import TaskMessage from './TasksComponents/TaskMessage'

/* Type imports ------------------------------------------------------------- */
import type { Tache } from 'API/__generated__/Api'
import { TypeTache } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
  justify-content: stretch;
`

const LoaderContainer = styled.div`
  text-align: center;
  padding-top: 100px;
`

const OuterGridContainer = styled(GridContainer)`
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface TasksPageProps {}

const TasksPage: React.FC<TasksPageProps> = () => {
  const authInfo = useAuthInfo()
  const [ filteredList, setFilteredList ] = useState<Tache[]>([])
  const [ pagination, setPagination ] = useState<{startIndex: number; limit: number}>({ startIndex: 0, limit: 20 })
  const [ mandantList, setMandantList ] = useState<string[]>([])
  const [ taskTypeFilter, setTaskTypeFilter ] = useState<TypeTache | ''>('')
  const [ mandantFilter, setMandantFilter ] = useState<string>('')
  const [ typeFilter, setTypeFilter ] = useState<string>('')
  const [ statutFilter, setStatutFilter ] = useState<string>('')

  const {
    currentData: tasks = [],
    isFetching: isFetchingTasks,
  } = useGetTasksQuery(
    {
      collaborateur: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '',
      startIndex: pagination.startIndex,
      limit: pagination.limit,
    },
    { skip: !isValidString(authInfo?.currentCollaborateur?.refAnnuaire.refComplete) },
  )
  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterNatureListQuery()
  const {
    currentData: caseStateCodeList = [],
    isFetching: isFetchingCaseStateCodeList,
  } = useGetCaseStateCodeListQuery()
  const {
    currentData: casesChips = [],
    isFetching: isFetchingCasesChips,
  } = useGetCasesChipListQuery(
    {
      modeSimple: true,
      dossiers: tasks.map((task) => task.dossier.id),
    },
    { skip: tasks.length === 0 },
  )

  useEffect(() => {
    if (!isFetchingTasks) {
      setFilteredList(tasks)
      setMandantList([ ...new Set(tasks.map((task) => task.dossier.mandant)) ].sort((a, b) => a.localeCompare(b)))
    }
  }, [ isFetchingTasks ])

  useEffect(() => {
    if (!isFetchingTasks && tasks.length > 0) {
      setFilteredList(tasks.filter((task) =>
        (taskTypeFilter === '' || task.typeTache === taskTypeFilter) &&
        (mandantFilter === '' || task.dossier.mandant === mandantFilter) &&
        (typeFilter === '' || task.dossier.natureSinistre.code === typeFilter) &&
        (statutFilter === '' || task.dossier.etat.code === statutFilter),
      ))
    }
  }, [
    taskTypeFilter,
    mandantFilter,
    typeFilter,
    statutFilter,
    tasks,
    isFetchingTasks,
  ])

  const onClickPrev = (): void => {
    const newStartIndex = pagination.startIndex - parseInt(pagination.limit.toString()) >= 0 ? pagination.startIndex - parseInt(pagination.limit.toString()) : 0
    setPagination({
      ...pagination,
      startIndex: newStartIndex,
    })
    setMandantFilter('')
  }

  const onClickNext = (): void => {
    const newStartIndex = pagination.startIndex + parseInt(pagination.limit.toString())
    setPagination({ ...pagination, startIndex: newStartIndex })
    setMandantFilter('')
  }

  const handleLimitChange = (newLimit: number): void => {
    setPagination({ ...pagination, limit: newLimit })
    setMandantFilter('')
  }

  return (
    <>
      <HeaderWithSearch title="Tâches" />
      <PageContainer>
        <OuterGridContainer>
          <GridContainer>
            <div>
              <FormBoldTitle>
                Type de tâche
              </FormBoldTitle>
              <Select
                value={taskTypeFilter as string}
                onChange={(e): void => setTaskTypeFilter(e.target.value as TypeTache)}
                fullWidth
                renderValue={verifySelectFieldValue(taskTypeFilter)}
                displayEmpty
                disabled={isFetchingTasks || isFetchingCaseStateCodeList || isFetchingDisasterNatureList}
              >
                <MenuItem
                  value=""
                  key=""
                >
                  Sélectionner
                </MenuItem>
                {
                  Object.entries(TypeTache)
                    .filter((value): boolean => isNaN(Number(value[0])))
                    .map((option) => (
                      <MenuItem
                        value={option[0]}
                        key={option[0]}
                      >
                        {option[1]}
                      </MenuItem>
                    ))
                }
              </Select>
            </div>
            <div>
              <FormBoldTitle>
                Mandant du dossier
              </FormBoldTitle>
              <Select
                value={mandantFilter}
                onChange={(e): void => setMandantFilter(e.target.value)}
                fullWidth
                renderValue={verifySelectFieldValue(mandantFilter)}
                displayEmpty
                disabled={isFetchingTasks || isFetchingCaseStateCodeList || isFetchingDisasterNatureList}
              >
                <MenuItem
                  value=""
                  key=""
                >
                  Sélectionner
                </MenuItem>
                {
                  mandantList.map((option) => (
                    <MenuItem
                      value={option}
                      key={option}
                    >
                      {option}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
          </GridContainer>
          <GridContainer>
            <div>
              <FormBoldTitle>
                Type de sinistre
              </FormBoldTitle>
              <Select
                value={typeFilter}
                onChange={(e): void => setTypeFilter(e.target.value)}
                fullWidth
                renderValue={verifySelectFieldValue(typeFilter)}
                displayEmpty
                disabled={isFetchingTasks || isFetchingCaseStateCodeList || isFetchingDisasterNatureList}
              >
                <MenuItem
                  value=""
                  key=""
                >
                  Sélectionner
                </MenuItem>
                {
                  disasterNatureList.map((option, index) => (
                    <MenuItem
                      value={option.code}
                      key={`${option.code}-${index}`}
                    >
                      {option.libelle}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
            <div>
              <FormBoldTitle>
                Statut du dossier
              </FormBoldTitle>
              <Select
                value={statutFilter}
                onChange={(e): void => setStatutFilter(e.target.value)}
                fullWidth
                renderValue={verifySelectFieldValue(statutFilter)}
                displayEmpty
                disabled={isFetchingTasks || isFetchingCaseStateCodeList || isFetchingDisasterNatureList}
              >
                <MenuItem
                  value=""
                  key=""
                >
                  Sélectionner
                </MenuItem>
                {
                  caseStateCodeList.map((option, index) => (
                    <MenuItem
                      value={option.code?.code}
                      key={`${option.code?.code}-${index}`}
                    >
                      {option.code?.libelle}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
          </GridContainer>
        </OuterGridContainer>
        <PaginationButtons
          prevText="Tâches précédentes"
          nextText="Tâches suivantes"
          prevDisabled={pagination.startIndex === 0}
          nextDisabled={tasks.length < parseInt(pagination.limit.toString())}
          limit={pagination.limit}
          onChangeLimit={handleLimitChange}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
        />
        <CardContainer>
          {
            (isFetchingTasks || isFetchingCaseStateCodeList || isFetchingDisasterNatureList) && tasks.length === 0 ?
              <LoaderContainer>
                <CircularProgress />
              </LoaderContainer> :
              filteredList.map((task, index) => (
                <TaskMessage
                  key={`${task.id}-${index}`}
                  task={task}
                  caseChips={casesChips.find((chips) => chips.dossier === task.dossier.id)?.listePastilles}
                  isFetchingChip={isFetchingCasesChips}
                />
              ))
          }
        </CardContainer>
        <PaginationButtons
          prevText="Tâches précédentes"
          nextText="Tâches suivantes"
          prevDisabled={pagination.startIndex === 0}
          nextDisabled={tasks.length < parseInt(pagination.limit.toString())}
          limit={pagination.limit}
          onChangeLimit={handleLimitChange}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
        />
      </PageContainer>
    </>
  )
}

export default TasksPage
